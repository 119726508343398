export const getApiUrl = (): string => {
  switch (process.env.GATSBY_APP_ENV) {
    case 'production':
      return 'https://3j7pdp5ks5dlxipobwdccbrify.appsync-api.eu-central-1.amazonaws.com/graphql';
    case 'acceptance':
      return 'https://fzefafvik5g3vnw4mhk7w5itby.appsync-api.eu-central-1.amazonaws.com/graphql';
    case 'development':
    default:
      return 'https://ywt2quyvbre5ddhkw7wafau4rm.appsync-api.eu-central-1.amazonaws.com/graphql';
  }
};
