import * as i from 'types';
import * as React from 'react';
import { useLocation } from '@reach/router';

import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';

import { RadioFormOption, RadioFormThreeColumnGrid } from './styled';

export const RadioForm: React.FC<RadioFormProps> = ({
  questionId,
  options,
}) => {
  const { answers, updateAnswer, questions } = useQuestionnaireContext();
  const location = useLocation();
  const isInFullQuestionnaire = location.pathname.includes('/questions');
  const isChargingId = questionId === 'chargingStation';

  if (!questions) return null;

  const onSelect = (value: string) => {
    const updatedAnswers = {
      ...answers,
      [questionId]: value,
      ...(isChargingId && {
        chargingAmount: {
          employees: value === 'yes-home' ? 1 : 0,
          business: 0,
        },
      }),
    };

    updateAnswer(updatedAnswers);
  };

  return isInFullQuestionnaire ? (
    <>
      {options.map((option) => (
        <RadioFormOption
          key={option.key}
          onClick={() => option.key && onSelect(option.key)}
          isActive={answers?.[transformQuestionId(questionId)] === option.key}
          id={`button_${option.key}`}
          htmlFor={option.key}
        >
          <input
            type="radio"
            id={option.key}
            name={questionId}
            value={option.value}
          />
          {option.value}
        </RadioFormOption>
      ))}
    </>
  ) : (
    <RadioFormThreeColumnGrid>
      {options.map((option) => (
        <RadioFormOption
          key={option.key}
          onClick={() => option.key && onSelect(option.key)}
          isActive={answers?.[transformQuestionId(questionId)] === option.key}
          fullWidth={options.length > 2}
          id={`button_${option.key}`}
        >
          {option.value}
        </RadioFormOption>
      ))}
    </RadioFormThreeColumnGrid>
  );
};

type RadioFormProps = {
  questionId: i.QuestionIds;
  options: i.Option[];
};
