import * as i from 'types';
import * as React from 'react';

import { NumberInput } from 'common/form';
import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';

import { NumberInputFormOption, NumberInputTitle } from './styled';

export const NumberInputForm: React.FC<RadioFormProps> = ({
  questionId,
  options,
}) => {
  const { answers, updateAnswer } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as i.NumberInput;

  const onChange = (value: number, key: string) => {
    updateAnswer({
      ...answers,
      [questionId]: {
        ...(answers?.[transformQuestionId(questionId)] as i.MultipleAnswer),
        [key]: value,
      },
    });
  };

  return (
    <Grid>
      {options.map((option) => {
        const isActive = option.key
          ? typeof answer?.[option.key] === 'number' && answer[option.key] > 0
          : false;

        return (
          <NumberInputFormOption key={option.key} isActive={isActive} isFlex>
            <NumberInputTitle>{option.value}</NumberInputTitle>
            <NumberInput
              onChange={onChange}
              maxAmount={option.maxAmount}
              options={option.key}
              initialValue={option.key ? answer?.[option.key] : undefined}
            />
          </NumberInputFormOption>
        );
      })}
    </Grid>
  );
};

type RadioFormProps = {
  questionId: i.QuestionIds;
  options: i.Option[];
};
