import wrapWithProvider from './src/wrap-with-provider';

export const wrapRootElement = wrapWithProvider;

export const onRouteUpdate = ({ location }) => {
  // Remove the stored number of to be shown items from storage when leaving offer scope
  if (!location.pathname.includes('aanbod')) {
    sessionStorage.removeItem('carsOverviewPage');
    sessionStorage.removeItem('chargingStationOverviewPage');
  }
};
