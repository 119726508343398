import * as i from 'types';
import * as React from 'react';

import { RangeSlider } from 'common/form';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';

import {
  DepreciationAnswer,
  DepreciationAnswerContainer,
  DepreciationSubtitle,
  DepreciationTitle,
} from './styled';

const flowConfigurations = {
  business: {
    max: '10',
    defaultValue: '5',
    steps: 10,
  },
  consumer: {
    max: '15',
    defaultValue: '6',
    steps: 15,
  },
};

export const DepreciationSlider: React.FC<RadioFormProps> = ({
  questionId,
}) => {
  const { answers, updateAnswer } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as string;
  const flowType = answers?.userType === 'business' ? 'business' : 'consumer';
  const { max, defaultValue, steps } = flowConfigurations[flowType];

  React.useEffect(() => {
    if (answers) {
      updateAnswer({
        ...answers,
        [questionId]: answer || defaultValue,
      });
    }
  }, []);

  const onChange = (value: string) => {
    updateAnswer({
      ...answers,
      [questionId]: value,
    });
  };

  const calculateMargin = () => {
    const multiplier = parseInt(answer) - 1;
    const total = (100 / (steps - 1)) * multiplier;
    let correction = 1.5;

    if (total > 90) correction = 6;
    else if (total > 75) correction = 4.5;
    else if (total > 55) correction = 4;
    else if (total > 45) correction = 3.5;
    else if (total > 35) correction = 3;
    else if (total > 25) correction = 2.5;
    else if (total > 10) correction = 2;

    return total - correction;
  };

  return (
    <>
      <RangeSlider
        min="1"
        max={max}
        defaultValue={defaultValue}
        step="1"
        steps={steps}
        {...{ answer, onChange }}
        isDeprecationSlider
      />
      <DepreciationAnswerContainer>
        <DepreciationAnswer marginLeft={calculateMargin()}>
          <DepreciationTitle>{answer}</DepreciationTitle>
          <DepreciationSubtitle>jaar</DepreciationSubtitle>
        </DepreciationAnswer>
      </DepreciationAnswerContainer>
    </>
  );
};

type RadioFormProps = {
  questionId: i.QuestionIds;
};
