import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { BoxCss } from 'common/layout';

export const RadioFormOption = styled.div<RadioFormOptionProps>`
  ${BoxCss};

  ${media.tablet<RadioFormOptionProps>`
    width: 465px;

    ${({ fullWidth }) => fullWidth && css`
      width: 100%;
    `}
  `}
`;

type RadioFormOptionProps = {
  isActive: boolean;
  fullWidth?: boolean;
};
