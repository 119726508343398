import * as i from 'types';

import { solarPanels } from 'services';

export const calculateSolarPanelsAmount = (answers?: i.Answers) => {
  if (!answers) return 0;

  if (answers.userType === 'consumer' && answers.solarPanels === 'no-yes') {
    return 8;
  }

  const totalCars =
    (answers.fleet &&
      Object.values(answers.fleet).reduce((acc, value) => acc + value, 0)) ||
    0;

  // Calculate the number of sets of one or two cars
  const setsOfCars = Math.ceil(totalCars / 2);

  // 8 solar panels are needed per set of one or two cars
  return setsOfCars * 8;
};

export const calculateSolarPanels = (answers?: i.Answers) => {
  if (!answers || !answers.solarPanels || answers.solarPanels !== 'no-yes') {
    return {
      amount: 0,
      oneOffCosts: 0,
    };
  }

  const amount = calculateSolarPanelsAmount(answers);
  const oneOffCosts = amount * solarPanels.priceIncludingAssembly;

  return {
    amount,
    oneOffCosts,
  };
};
