import * as i from 'types';
import * as React from 'react';

import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';

import { MultiSelectOption } from './styled';

export const MultiSelectForm: React.FC<MultiSelectFormProps> = ({ questionId, options }) => {
  const { answers, updateAnswer, questions } = useQuestionnaireContext();
  const selectedAnswers = answers?.[transformQuestionId(questionId)];
  const [selection, setSelection] = React.useState(Array.isArray(selectedAnswers) ? selectedAnswers : []);

  React.useEffect(() => {
    updateAnswer({
      ...answers,
      [questionId]: selection,
    });
  }, [selection]);

  if (!questions) return null;

  const toggleSelected = (value: keyof i.UsageType) => {
    const newSelection = [...selection];
    !newSelection.includes(value) ? newSelection.push(value) : newSelection.splice(newSelection.indexOf(value), 1);
    setSelection(newSelection);
  };

  return (
    <Grid showGrid={options.length > 2}>
      {options.map((option) => (
        <MultiSelectOption
          key={option.key}
          onClick={() => toggleSelected(option.key as keyof i.UsageType)}
          isActive={selection.includes(option.key as keyof i.UsageType)}
          fullWidth={options.length > 2}
          id={`button_${option.key}`}
        >
          {option.value}
        </MultiSelectOption>
      ))}
    </Grid>
  );
};

type MultiSelectFormProps = {
  questionId: i.QuestionIds;
  options: i.Option[];
};
