import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { BoxCss } from 'common/layout';

export const MultiSelectOption = styled.div<MultiSelectOptionProps>`
  ${BoxCss};

  ${media.tablet<MultiSelectOptionProps>`
    width: 264px;

    ${({ fullWidth }) => fullWidth && css`
      width: 100%;
    `}
  `}
`;

type MultiSelectOptionProps = {
  isActive: boolean;
  fullWidth?: boolean;
};
