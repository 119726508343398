import { gql } from 'graphql-request';

export const GET_USER_QUERY = gql`
  query GetUser ($id: ID!) {
    getUser(id: $id) {
      id,
      answers,
      busFinder,
      email,
      phone,
      name,
      companyName,
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser {
    createUser {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!,
    $answers: AWSJSON,
    $busFinder: AWSJSON,
  ) {
    updateUser(
      input: {
        id: $id,
        answers: $answers,
        busFinder: $busFinder,
      }
    ) {
      id
    }
  }
`;

export const UPDATE_USER_OUTCOME = gql`
  mutation UpdateUserOutcome(
    $id: ID!,
    $outcome: String!,
  ) {
    updateUserOutcome(
      input: {
        id: $id,
        outcome: $outcome,
      }
    ) {
      id
    }
  }
`;

