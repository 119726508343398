import * as i from 'types';

import { transformQuestionId } from 'services';

export const checkValid = (
  visibleQuestions: i.Question[],
  step: number,
  answers?: i.Answers,
) => {
  const question = visibleQuestions?.[step];
  if (!question) return false;

  const answer = answers?.[transformQuestionId(question?.questionId)];
  if (!answer) return false;

  switch (question?.type) {
    case 'radio':
    case 'depreciation':
    case 'slider':
    case 'stepsSlider':
    case 'radioWithFollowUpInput':
    case 'inputCombination': {
      return typeof answer === 'string';
    }
    case 'multiSelect': {
      return Array.isArray(answer) && answer.length > 0;
    }
    case 'numberInput':
    case 'fleetInput':
    case 'carSelector': {
      const check = Object.values(answer).filter((value) => value > 0);
      const total = check.reduce((partial_sum, a) => partial_sum + a, 0);
      return total > 0;
    }
    case 'radioCombination': {
      if (!answers.chargingAmount) return null;

      const totalChargingPoints =
        answers.chargingAmount.employees + answers.chargingAmount.business;
      return totalChargingPoints > 0 || answers.chargingStation === 'no';
    }
    case 'radioCombinationType': {
      const totalEmployees = answers.employees;
      return (
        (totalEmployees && Number(totalEmployees) > 0) || answers.type === 'zzp'
      );
    }
    case 'fleetRange': {
      if (!answers.fleet) return null;
      const fleetAmount = Object.values(answers?.fleet).filter(
        (value) => value > 0,
      ).length;

      const total = Object.values(answer)
        .filter((value) => value > 0)
        .filter((value) => value < 100001).length;

      return fleetAmount === total;
    }
  }
};
