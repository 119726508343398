import { Button } from 'common/interaction';
import styled from 'styled-components';

import { hexToRgba, media } from 'styles/utils';

export const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;

export const ModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => hexToRgba(theme.colors.black, '0.25')};
`;

export const ModalWrapper = styled.div`
  z-index: 101;
  width: calc(100% - 32px);
  height: auto;
  max-height: 95%;
  display: flex;
  position: relative;
  flex-direction: column;

  ${media.tablet`
    position: absolute;
    top: 120px;
    transform: translateX(-50%, -50%);
    width: 480px;
    max-width: 100%;
  `}
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-overflow-scrolling: touch;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.ui.shadows};
  gap: 8px;
  padding: 16px 24px;
`;

export const ModalClose = styled(Button)`
  position: absolute;
  top: 16px;
  right: 24px;
  width: 40px;
  height: 40px;
`;
