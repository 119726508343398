import styled from 'styled-components';

import { media } from 'styles/utils';
import { Input } from 'common/form';
import { BoxCss } from 'common/layout';
import { Paragraph } from 'common/typography';

export const FleetRangeSelectorOptionContainer = styled.div<FleetSelectorOptionProps>`
  ${BoxCss};
  padding: 24px;
  gap: 8px;

  ${media.tablet`
    width: 340px;
  `}

  ${media.desktop`
    width: 456px;
  `}
`;

type FleetSelectorOptionProps = {
  isActive?: boolean;
  isFlex: boolean;
};

export const FleetRangeSelectorSubTitle = styled(Paragraph)`
  padding-bottom: 40px;
`;

export const FleetRangeInput = styled(Input)`
  width: 148px;
`;

export const FleetRangeCarImage = styled.img`
  width: 120px;

  ${media.desktop`
    top: 10px;
    right: 0;
    width: unset;
    max-height: 86px;
  `}
`;
