import styled from 'styled-components';

const labelSizes = {
  1: {
    size: '16px',
  },
  2: {
    size: '14px',
  },
  3: {
    size: '12px',
  },
};

export const Label = styled.p<LabelProps>`
  font-size: ${({ size }) => labelSizes[size || 1].size};
  font-weight: ${({ weight }) => weight || 700};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray['1200']};
  margin: 0;
`;

type LabelProps = {
  size?: 1 | 2 | 3;
  weight?: 400;
};
