import * as i from 'types';

import { transformPercentage } from 'services';

export const calculateVariableCosts = (
  variables: GatsbyTypes.ContentfulVariables,
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  let evPrice = 0;
  let petrolPrice = 0;
  let rangeAmount = {
    ev: {
      carOne: 0,
      carTwo: 0,
      carThree: 0,
      carFour: 0,
      carFive: 0,
      carSix: 0,
      vanOne: 0,
      vanTwo: 0,
      vanThree: 0,
      vanFour: 0,
      vanFive: 0,
      vanSix: 0,
    },
    petrol: {
      carOne: 0,
      carTwo: 0,
      carThree: 0,
      carFour: 0,
      carFive: 0,
      carSix: 0,
      vanOne: 0,
      vanTwo: 0,
      vanThree: 0,
      vanFour: 0,
      vanFive: 0,
      vanSix: 0,
    },
  };

  if (!answers) {
    return {
      evPrice,
      petrolPrice,
      rangeAmount,
    };
  }

  const { balance, fleet, range, chargingStation } = answers;

  const {
    energyCostsCityRegularCharging,
    energyCostsHighwayRegularCharging,
    energyCostsCityPublicCharging,
    energyCostsHighwayPublicCharging,
    petrolCostsCity,
    petrolCostsHighway,
  } = variables;
  const multipliers = {
    carOne: variables.personenautoCostMultiplier,
    carTwo: variables.personenautoCostMultiplier,
    carThree: variables.personenautoCostMultiplier,
    carFour: variables.personenautoCostMultiplier,
    carFive: variables.personenautoCostMultiplier,
    carSix: variables.personenautoCostMultiplier,
    vanOne: variables.kleineBestelwagenCostMultiplier,
    vanTwo: variables.kleineBestelwagenCostMultiplier,
    vanThree: variables.middelBestelwagenCostMultiplier,
    vanFour: variables.middelBestelwagenCostMultiplier,
    vanFive: variables.grootBestelwagenCostMultiplier,
    vanSix: variables.grootBestelwagenCostMultiplier,
  };

  if (
    fleet &&
    range &&
    typeof balance === 'string' &&
    energyCostsCityRegularCharging &&
    energyCostsHighwayRegularCharging &&
    energyCostsCityPublicCharging &&
    energyCostsHighwayPublicCharging &&
    petrolCostsCity &&
    petrolCostsHighway
  ) {
    const isPublicCharging = chargingStation === 'public-charging';
    const energyCostsCity = isPublicCharging
      ? energyCostsCityPublicCharging
      : energyCostsCityRegularCharging;
    const energyCostsHighway = isPublicCharging
      ? energyCostsHighwayPublicCharging
      : energyCostsHighwayRegularCharging;

    const evDifference = energyCostsCity - energyCostsHighway;
    const averageEvPricePerKm =
      transformPercentage(parseInt(balance)) * evDifference +
      energyCostsHighway;
    const petrolDifference = petrolCostsCity - petrolCostsHighway;
    const averagePetrolPricePerKm =
      transformPercentage(parseInt(balance)) * petrolDifference +
      petrolCostsHighway;
    const divider = period === 'year' ? 1 : 12;

    rangeAmount = Object.entries(fleet).reduce((object, [key, value]) => {
      const amount = range[key as keyof i.FleetInput];
      const type = key as i.CarTypes;
      const multiplier = multipliers[type];

      if (!multiplier || !amount) return object;

      // If the user selected that they either want solar panels or already have solar panels
      // then set the EV price to 0 because they will have no costs for fuel (energy)
      object.ev[type] =
        answers.solarPanels && answers.solarPanels !== 'no'
          ? 0
          : (value * amount * (averageEvPricePerKm * multiplier)) / divider;
      object.petrol[type] =
        (value * amount * (averagePetrolPricePerKm * multiplier)) / divider;

      return object;
    }, rangeAmount);

    const totalRange = Object.entries(fleet)
      .map(([key, value]) => {
        const amount = range[key as keyof i.FleetInput];
        const type = key as i.CarTypes;
        const multiplier = multipliers[type];
        if (!multiplier || !amount) return 0;

        return amount * value * multiplier;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);

    evPrice = (totalRange * averageEvPricePerKm) / divider;
    petrolPrice = (totalRange * averagePetrolPricePerKm) / divider;
  }

  return {
    rangeAmount,
    // If the user selected that they either want solar panels or already have solar panels
    // then set the EV price to 0 because they will have no costs for fuel (energy)
    evPrice: answers.solarPanels && answers.solarPanels !== 'no' ? 0 : evPrice,
    petrolPrice,
  };
};
