import * as i from 'types';
import styled, { css } from 'styled-components';

import { getColor } from 'styles/utils';

const paragraphSizes = {
  1: {
    size: '16px',
    lineHeight: '24px',
  },
  2: {
    size: '14px',
    lineHeight: '20px',
  },
};

export const Paragraph = styled.p<ParagraphProps>`
  font-size: ${({ newSize }) => paragraphSizes[newSize || 1].size};
  font-weight: ${({ weight }) => weight || 400};
  line-height: ${({ newSize }) => paragraphSizes[newSize || 1].lineHeight};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  margin: ${({ margin }) => margin || '18px 0'};
  color: ${({ theme, variant }) =>
    getColor(theme, variant || ['gray', '1200'])};
  text-align: ${({ align }) => align || 'left'};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
   `}
`;

type ParagraphProps = {
  newSize?: 1 | 2; // TODO: Rename to 'size' after codebase cleanup
  weight?: 400 | 500 | 600 | 700;
  variant?: i.TextColors;
  align?: 'left' | 'center' | 'right';
  noMargin?: boolean;
  fontStyle?: 'italic';
  margin?: string;

  // TODO: Remove after codebase cleanup
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 26;
  lineHeight?: number;
  mobileSize?: number;
};
