import * as React from 'react';

import { sizes } from 'styles/utils';
import { isServer } from 'services';
import { useMediaQuery } from 'hooks';

export const useDevice = (debounce = 500) => {
  const [determined, setDetermined] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTablet, setIsTablet] = React.useState(false);
  const [isDesktop, setIsDesktop] = React.useState(false);
  const [isLarge, setIsLarge] = React.useState(false);
  const [mediaActive] = useMediaQuery(
    {
      tablet: `(max-width: ${sizes.desktop - 1}px)`,
      mobile: `(max-width: ${sizes.tablet - 1}px)`,
      desktop: `(min-width: ${sizes.desktop}px)`,
      large: `(min-width: ${sizes.large}px)`,
    },
    debounce,
  );

  React.useEffect(() => {
    setDetermined(true);
    setIsMobile(!isServer && mediaActive === 'mobile');
    setIsTablet(!isServer && mediaActive === 'tablet');
    setIsDesktop(!isServer && mediaActive === 'desktop');
    setIsLarge(!isServer && mediaActive === 'large');
  }, [mediaActive]);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLarge,
    determined,
  };
};
