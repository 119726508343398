import * as i from 'types';
import * as React from 'react';

import { useQuestionnaireContext } from 'modules/questionnaire';
import { NumberInput } from 'common/form';
import { transformQuestionId } from 'services';

import {
  NumberInputFormOption,
  NumberInputTitle,
} from '../NumberInputForm/styled';
import { RadioFormOption } from './styled';

export const RadioCombination: React.FC<RadioCombinationProps> = ({
  questionId,
  options,
  secondaryQuestion,
}) => {
  const isChargingId = questionId === 'chargingStation';
  const { answers, updateAnswer, questions } = useQuestionnaireContext();
  const [numberValues, setNumberValues] = React.useState<any>({});
  const secondaryAnswer = answers && answers[secondaryQuestion];
  const [resetNumberInput, setResetNumberInput] = React.useState(false);

  React.useEffect(() => {
    const numberObject: any = {};

    options.forEach((option) => {
      if (option.type)
        numberObject[option.key] = answers?.chargingAmount?.[option.key] || 0;
    });

    setNumberValues(numberObject);
  }, [answers?.chargingAmount]);

  if (!questions) return null;

  const onChange = (value: number, key: string) => {
    setNumberValues({
      ...(numberValues as i.MultipleAnswer),
      [key]: value,
    });

    updateAnswer({
      ...answers,
      [questionId]: isChargingId ? 'yes' : 'mkb',
      [secondaryQuestion]: isChargingId
        ? {
            ...(numberValues as i.MultipleAnswer),
            [key]: value,
          }
        : value,
    });
  };

  const onSelect = () => {
    resetNumberInputs();
    updateAnswer({
      ...answers,
      [secondaryQuestion]: isChargingId
        ? {
            employees: 0,
            business: 0,
          }
        : {
            employees: 0,
          },
      [questionId]: isChargingId ? 'no' : 'zzp',
    });
  };

  const resetNumberInputs = () => {
    setResetNumberInput((prev) => !prev);
  };

  return (
    <>
      {options.map((option) => {
        const key = option && option.key;
        const answerIsNumber = typeof secondaryAnswer === 'number';

        return option.type ? (
          <NumberInputFormOption
            key={option.key}
            isActive={
              answerIsNumber
                ? secondaryAnswer > 0
                : secondaryAnswer && secondaryAnswer[key] > 0
            }
            isFlex
          >
            <NumberInputTitle>{option.value}</NumberInputTitle>
            <NumberInput
              resetTrigger={resetNumberInput}
              onChange={onChange}
              maxAmount={option.maxAmount}
              options={option.key}
              initialValue={
                answerIsNumber
                  ? secondaryAnswer || 0
                  : (secondaryAnswer && secondaryAnswer[key]) || 0
              }
            />
          </NumberInputFormOption>
        ) : (
          <RadioFormOption
            key={option.key}
            onClick={() => onSelect()}
            isActive={
              answers?.[transformQuestionId(questionId)] === 'no' ||
              answers?.[transformQuestionId(questionId)] === 'zzp'
            }
            id={`button_${option.key}`}
          >
            {option.value}
          </RadioFormOption>
        );
      })}
    </>
  );
};

type RadioCombinationProps = {
  questionId: i.QuestionIds;
  options: {
    key: string;
    answer: string;
    type?: string;
    value: string;
    maxAmount?: number;
  }[];
  secondaryQuestion: i.SecondaryQuestions;
};
