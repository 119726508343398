import styled from 'styled-components';
import { media } from 'styles/utils';
export const DepreciationAnswerContainer = styled.div`
  margin-top: 16px;
`;

export const DepreciationAnswer = styled.div<AnswerProps>`
  margin-left: calc(${({ marginLeft }) => marginLeft}% - 4%); // TODO: Improve this correction for mobile
  text-align: center;
  width: 50px;

  ${media.tablet<AnswerProps>`
    margin-left: ${({ marginLeft }) => marginLeft}%;
  `}
`;

type AnswerProps = {
  marginLeft?: number;
};

export const DepreciationTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.orange['600']};
  margin: 0;
`;

export const DepreciationSubtitle = styled.p`
  line-height: 28px;
  margin: 0;
`;
