import * as i from 'types';

import {
  calculateInfrastructurePrice,
  carSelector,
  transformPercentage,
} from 'services';
import { calculateFleetValues } from '../';

export const calculateTaxBenefits = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  if (!answers) return 0;

  const fleet = calculateFleetBenefits(cars, variables, answers);
  const infrastructure = calculateInfrastructureBenefits(variables, answers);

  return fleet + infrastructure.total;
};

export const calculateFleetBenefits = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  if (!answers) return 0;

  const {
    taxBenefitPassengerCar,
    taxBenefitVan,
    maxAmountOfCarsForTaxBenefit,
    taxBenefitNettoPercentage,
  } = variables;

  if (
    answers.financing !== 'operational' &&
    answers.fleet &&
    typeof taxBenefitPassengerCar === 'number' &&
    taxBenefitVan &&
    maxAmountOfCarsForTaxBenefit &&
    taxBenefitNettoPercentage
  ) {
    return Object.entries(answers.fleet)
      .map(([key, value]) => {
        const car = carSelector(cars, key as keyof i.FleetInput, 'ev', answers, true);

        if (!car || !car.price) return 0;

        const taxBenefitPercentage = car.comparison?.startsWith('car')
          ? taxBenefitPassengerCar
          : taxBenefitVan;
        const brutoBenefit =
          (car.price - 11000) * transformPercentage(taxBenefitPercentage);

        if (car.comparison?.startsWith('van')) {
          return (
            value *
            brutoBenefit *
            transformPercentage(taxBenefitNettoPercentage)
          );
        }

        // Passanger cars do not get tax benefits
        return 0;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return 0;
};

export const calculateInfrastructureBenefits = (
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  const infrastructureBenefits: i.TaxInfrastructureType = {
    employees: 0,
    business: 0,
    total: 0,
  };

  if (!answers) return infrastructureBenefits;
  const { fleet, type, chargingStation, chargingAmount, financing } = answers;
  const {
    taxBenefitInfrastructureHome,
    taxBenefitInfrastructureZzp,
    taxBenefitInfrastructureBusiness,
    taxBenefitNettoPercentage,
    infrastructureHigh,
    infrastructureLow,
  } = variables;
  const totalFleet = fleet ? calculateFleetValues(fleet) : 0;

  if (
    financing !== 'operational' &&
    chargingStation === 'yes' &&
    chargingAmount &&
    typeof taxBenefitInfrastructureHome === 'number' &&
    taxBenefitInfrastructureZzp &&
    taxBenefitInfrastructureBusiness &&
    taxBenefitNettoPercentage &&
    infrastructureHigh &&
    infrastructureLow &&
    totalFleet > 0
  ) {
    (
      Object.entries(chargingAmount) as [i.ChargingAmountKeys, number][]
    ).forEach(([key, value]) => {
      const amount = calculateInfrastructurePrice(
        key as i.ChargingAmountKeys,
        value,
        infrastructureHigh,
        infrastructureLow,
      );
      let taxBenefitPercentage: number;

      if (type === 'zzp') taxBenefitPercentage = taxBenefitInfrastructureZzp;
      else if (key === 'employees')
        taxBenefitPercentage = taxBenefitInfrastructureHome;
      else taxBenefitPercentage = taxBenefitInfrastructureBusiness;

      const benefit =
        amount *
        transformPercentage(taxBenefitPercentage) *
        transformPercentage(taxBenefitNettoPercentage);
      infrastructureBenefits[key] = benefit;
    });

    infrastructureBenefits.total =
      infrastructureBenefits.business + infrastructureBenefits.employees;
    return infrastructureBenefits;
  }

  return infrastructureBenefits;
};

export const calculateTaxBenefitsIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  const taxBenefitPerCar = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers) return taxBenefitPerCar;

  const {
    taxBenefitPassengerCar,
    taxBenefitVan,
    maxAmountOfCarsForTaxBenefit,
    taxBenefitNettoPercentage,
  } = variables;

  if (
    answers.financing !== 'operational' &&
    answers.fleet &&
    typeof taxBenefitPassengerCar === 'number' &&
    taxBenefitVan &&
    maxAmountOfCarsForTaxBenefit &&
    taxBenefitNettoPercentage
  ) {
    (Object.entries(answers.fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const car = carSelector(cars, key as keyof i.FleetInput, 'ev', answers, true);

        if (car && car.price) {
          const taxBenefitPercentage = car.comparison?.startsWith('car')
            ? taxBenefitPassengerCar
            : taxBenefitVan;
          const brutoBenefit =
            (car.price - 11000) * transformPercentage(taxBenefitPercentage);

          if (car.comparison?.startsWith('van')) {
            taxBenefitPerCar[key] =
              value *
              brutoBenefit *
              transformPercentage(taxBenefitNettoPercentage);
          } else {
            taxBenefitPerCar[key] = 0;
          }
        } else {
          taxBenefitPerCar[key] = 0;
        }
      },
    );
  }

  return taxBenefitPerCar;
};
