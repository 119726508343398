import * as i from 'types';
import * as React from 'react';

import { RangeSlider } from 'common/form';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { formatPrice, transformQuestionId } from 'services';

import {
  SliderAnswerContainer,
  SliderAnswer,
  SliderTitle,
  SliderSubtitle,
} from './styled';

export const Slider: React.FC<SliderProps> = ({ questionId, sliderValues }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const answerWidth = ref.current?.offsetWidth;
  const { answers, updateAnswer } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as string;

  const { min, max, step, default: defaultValue, units } = sliderValues;
  const hasEuroPrefix = units === 'euro';

  React.useEffect(() => {
    if (answers) {
      updateAnswer({
        ...answers,
        [questionId]: answer || defaultValue,
      });
    }
  }, [sliderValues]);

  const onChange = (value: string) => {
    updateAnswer({
      ...answers,
      [questionId]: value,
    });
  };

  const calculateMargin = (answer: string) => {
    const numberOfSteps = (parseInt(max) - parseInt(min)) / parseInt(step);
    const stepNumber = (parseInt(answer) - parseInt(min)) / parseInt(step);
    return (stepNumber / numberOfSteps) * 100;
  };

  return (
    <>
      <RangeSlider min={min} max={max} step={step} {...{ answer, onChange }} />
      <SliderAnswerContainer>
        <SliderAnswer
          marginLeft={calculateMargin(answer)}
          ref={ref}
          answerWidth={answerWidth}
        >
          <SliderTitle>
            {formatPrice(parseInt(answer, 10), hasEuroPrefix, true)}
          </SliderTitle>
          {!hasEuroPrefix && <SliderSubtitle>{units}</SliderSubtitle>}
        </SliderAnswer>
      </SliderAnswerContainer>
    </>
  );
};

type SliderProps = {
  questionId: i.QuestionIds;
  sliderValues: i.SliderValues;
};
