import * as i from 'types';

export const outcomeTextSelector = (
  outcome: string, variables: GatsbyTypes.ContentfulVariables,
) => {
  switch (outcome) {
    case 'favorable':
      return {
        title: variables.outcomeFavorableTitle?.outcomeFavorableTitle,
        text: variables.outcomeFavorableText?.outcomeFavorableText,
      };
    case 'non-favorable':
      return {
        title: variables.outcomeNotFavorableTitle?.outcomeNotFavorableTitle,
        text: variables.outcomeNotFavorableText?.outcomeNotFavorableText,
      };
    case 'neutral':
      return {
        title: variables.outcomeNeutralTitle?.outcomeNeutralTitle,
        text: variables.outcomeNeutralText?.outcomeNeutralText,
      };
    default:
      return null;
  }
};

export const totalOutcomeTextSelector = (
  outcome: string, variables: GatsbyTypes.ContentfulVariables, financeType: i.FinanceType,
) => {
  switch (outcome) {
    case 'favorable':
      return {
        title: variables.outcomeTotalFavorableTitle?.outcomeTotalFavorableTitle,
        text: financeType === 'operational' || financeType === 'financial'
          ? variables.outcomeTotalFavorableLeaseText?.outcomeTotalFavorableLeaseText
          : variables.outcomeTotalFavorableText?.outcomeTotalFavorableText,
      };
    case 'non-favorable':
      return {
        title: variables.outcomeTotalNeutralTitle?.outcomeTotalNeutralTitle,
        text: variables.outcomeTotalNotFavorableText?.outcomeTotalNotFavorableText,
      };
    case 'neutral':
      return {
        title: variables.outcomeTotalNeutralTitle?.outcomeTotalNeutralTitle,
        text: variables.outcomeTotalNeutralText?.outcomeTotalNeutralText,
      };
    default:
      return null;
  }
};
