import styled, { css } from 'styled-components';
import * as i from 'types';

export const InputFieldCss = css<InputProps>`
  color: ${({ theme }) => theme.colors.black};
  height: 46px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.border};
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-appearance: none;
  line-height: normal;
  overflow: hidden;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray.border};
  }

  &:active,
  &:focus {
    caret-color: ${({ theme }) => theme.colors.gray['1200']};
    border-color: ${({ theme }) => theme.colors.orange['600']};
    outline: none;
  }

  &[readonly],
  &[disabled] {
    border-color: ${({ theme }) => theme.colors.gray.border};
    color: ${({ theme }) => theme.colors.gray['800']};
    background-color: ${({ theme }) => theme.colors.gray.disabled};
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red};

      &:active,
      &:focus {
        caret-color: ${({ theme }) => theme.colors.red};
        border-color: ${({ theme }) => theme.colors.red};
        outline: none;
      }
    `};

  ${({ as }) =>
    as === 'textarea' &&
    css`
      max-width: 100%;
      min-height: 150px;
      padding: 8px;
      resize: vertical;
      overflow-y: scroll;
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: 60px;
    `}
`;

export type InputProps = Pick<i.DefaultInputProps, 'autoFocus'> & {
  error?: boolean;
  hasIcon?: boolean;
  as?: 'textarea';
};

export const StyledInput = styled.input`
  ${InputFieldCss};
`;

export const InputIcon = styled.div<IconWrapperProps>`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    max-width: 30px;
    max-height: 100%;
    fill: ${({ theme }) => theme.colors.black};
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
      opacity: 0.4;
    `};
`;

type IconWrapperProps = Pick<i.DefaultInputProps, 'readOnly'>;

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  width: 100%;

  ${({ $iconPosition }) =>
    $iconPosition === 'right' &&
    css`
      ${StyledInput} {
        padding: 0 60px 0 8px;
      }

      ${InputIcon} {
        left: auto;
        right: 16px;
      }
    `}
`;

type InputWrapperProps = Pick<i.DefaultInputProps, '$iconPosition'>;
