import * as React from 'react';
import { navigate } from 'gatsby';

import RefreshIcon from 'vectors/icons/refresh.svg';
import { ContentContainer } from 'common/layout';
import { Heading, Paragraph } from 'common/typography';
import { Button } from 'common/interaction';

import {
  ErrorPageButtons,
  ErrorPageContainer,
  ErrorPageContent,
  ErrorPageIcon,
} from './styled';

export const ErrorPage = ({
  title,
  description,
  primaryButton,
  secondaryButton,
}: ErrorPageProps) => {
  const pageTitle = title || 'Sorry, er zijn geen resultaten gevonden';
  const pageDescription =
    description ||
    'Controleer of u alle verplichte velden hebt ingevuld en probeer het opnieuw.';
  const pagePrimaryButton = primaryButton || {
    title: 'Pagina herladen',
    onClick: () => navigate(-1),
  };
  const pageSecondaryButton = secondaryButton || {
    title: 'Terug naar beginscherm',
    to: '/',
  };

  return (
    <ErrorPageContainer>
      <ContentContainer width={451}>
        <ErrorPageContent>
          <ErrorPageIcon />
          <Heading
            as="h2"
            margin="0 0 24px"
            marginDesktop="0 0 24px"
            isCentered
          >
            {pageTitle}
          </Heading>
          <Paragraph align="center" margin="0 0 40px">
            {pageDescription}
          </Paragraph>
        </ErrorPageContent>
      </ContentContainer>
      <ErrorPageButtons>
        <Button
          icon={<RefreshIcon />}
          to={pagePrimaryButton.to}
          onClick={pagePrimaryButton.onClick}
        >
          {pagePrimaryButton.title}
        </Button>
        <Button
          variant="secondary"
          to={pageSecondaryButton.to}
          onClick={pageSecondaryButton.onClick}
        >
          {pageSecondaryButton.title}
        </Button>
      </ErrorPageButtons>
    </ErrorPageContainer>
  );
};

type ErrorPageButton = {
  title: string;
  to?: string;
  onClick?: () => void;
};

type ErrorPageProps = {
  title?: string;
  description?: string;
  primaryButton?: ErrorPageButton;
  secondaryButton?: ErrorPageButton;
};
