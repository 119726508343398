import styled from 'styled-components';
import { media } from 'styles/utils';

import NoResultIcon from 'vectors/icons/no-result.svg';

export const ErrorPageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.background};
  padding: 168px 0;
  // minus the disclaimer footer height
  min-height: calc(100vh - 136.25px);
`;

export const ErrorPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorPageIcon = styled(NoResultIcon)`
  width: 80px;
  height: 80px;
  margin-bottom: 32px;
`;

export const ErrorPageButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 0 24px;

  & > button,
  & > a {
    width: 100%;
  }

  ${media.tablet`
    flex-direction: row;

    & > button,
    & > a {
      width: auto;
    }
  `}
`;
