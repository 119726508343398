import * as i from 'types';
import * as React from 'react';
import { navigate } from 'gatsby';

import { Modal } from 'common/general';
import { useModal } from 'hooks';
import CloseSvg from 'vectors/icons/close.svg';
import InfoSvg from 'vectors/icons/info.svg';

import { ModalTriggerContainer, TextButton } from './styled';

export const ModalTrigger: React.FC<ModalTriggerProps> = ({
  text,
  title,
  variant,
  size,
  link,
  newTab,
  asText,
  displayText,
  children,
}) => {
  const [modalOpen, openModal, closeModal] = useModal();

  const handleClick = () => {
    if (link) {
      if (newTab) {
        window.open(link, '_blank');
      } else {
        navigate(link);
      }
    } else {
      openModal();
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal.Container onClose={closeModal} title={title}>
          <Modal.Close
            onClick={closeModal}
            variant="secondary"
            $iconOnly
            icon={<CloseSvg />}
            aria-label="Sluiten"
          />
          {text || children}
        </Modal.Container>
      )}
      {!asText && (
        <ModalTriggerContainer
          onClick={handleClick}
          data-testid="questionnaireHelp"
          type="button"
          variant={variant}
          size={size}
        >
          <InfoSvg />
        </ModalTriggerContainer>
      )}
      {asText && <TextButton onClick={handleClick}>{displayText}</TextButton>}
    </>
  );
};

export type ModalTriggerProps = {
  text?: string | React.ReactNode;
  title?: string;
  variant?: i.TextColors;
  size?: 'small' | 'large';
  link?: string;
  displayText?: string;
  newTab?: boolean;
  asText?: boolean;
};
