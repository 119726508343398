import * as i from 'types';

import SolarPanelsImage from 'images/solarpanels.png';

// Solar panels are hardcoded and do not exist in Contentful. But they have the same object
// structure as Charging stations because they belong to the infrastructure table.
export const solarPanels: i.ChargingStation = {
  id: 'solarPanels',
  brand: 'Zonnepanelen',
  model: '',
  loadingCapacity: 0,
  shortDescription: '',
  intro: '',
  isInTcoTool: true,
  description: {
    description: '',
  },
  priceIncludingAssembly: 600,
  price: 0,
  image: {
    file: {
      id: '01',
      url: SolarPanelsImage,
    },
  },
  infraType: 'business',
};
