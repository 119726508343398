import styled from 'styled-components';

import { Paragraph } from 'common/typography';

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProgressBarText = styled(Paragraph)`
  margin: 0 12px 0 0;
  color: ${({ theme }) => theme.colors.orange['600']};
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.sand['200']};
  width: 100px;
  height: 4px;

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $value }) => $value}%;
    height: 4px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.orange['600']};
    transition: width 0.3s ease-in-out;
  }
`;

type ProgressBarProps = {
  $value: number;
};
