import * as i from 'types';

export const contentSelector = (
  input: ContentSelectorInput,
): ContentSelectorResponse[] => {
  const data = input?.edges.map((edge) => edge.node);
  return data;
};

type ContentSelectorInput =
  | GatsbyTypes.ContentfulQuestionsConnection
  | GatsbyTypes.ContentfulComponentBlogConnection
  | GatsbyTypes.ContentfulCarsConnection
  | GatsbyTypes.ContentfulChargingStationsConnection
  | GatsbyTypes.ContentfulVariablesConnection
  | GatsbyTypes.ContentfulOutcomesConnection
  | GatsbyTypes.ContentfulComponentHeaderBlockConnection;

type ContentSelectorResponse =
  | GatsbyTypes.ContentfulQuestions
  | GatsbyTypes.ContentfulChargingStations
  | GatsbyTypes.ContentfulComponentBlog
  | GatsbyTypes.ContentfulCars
  | GatsbyTypes.ContentfulVariables
  | GatsbyTypes.ContentfulOutcomes
  | i.ChargingStation
  | i.Service
  | i.Car
  | i.Question
  | GatsbyTypes.ContentfulComponentHeaderBlock;

export const carSelector = (
  cars: GatsbyTypes.ContentfulCars[],
  size: keyof i.FleetInput,
  type: 'ev' | 'petrol' = 'ev',
  answers: i.Answers,
  isInTcoTool?: boolean,
) => {
  let filteredCars = cars.filter(
    (car) => car.type === type && car.typeOfFlow === answers.userType,
  );

  if (isInTcoTool) {
    filteredCars = filteredCars.filter((car) => car.shouldBeInTcoTool === true);
  }

  return filteredCars.find((car) => car.comparison === size);
};
