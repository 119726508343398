import * as React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { ScrollProgressBar } from 'common/general';

import { MenuButton, MenuContainer, MenuContent, NNLogo } from './styled';

export const Menu: React.FC<MenuProps> = ({ useSimpleMenu }) => {
  const location = useLocation();
  const isResult = location.pathname.includes('/result');

  const startTool = () => {
    if (typeof window !== 'undefined') {
      if (window.lintrk != null) {
        window.lintrk('track', { conversion_id: 9667730 });
      }
    }
    navigate('/questions/userType');
  };

  return (
    <>
      <MenuContainer>
        <MenuContent>
          <a href="https://www.nn.nl/">
            <NNLogo aria-label="Nationale-Nederlanden - Home" />
          </a>
          {!useSimpleMenu && (
            <MenuButton
              onClick={startTool}
              id="button_start_calculation"
            >
              Bereken nu
            </MenuButton>
          )}
        </MenuContent>
      </MenuContainer>
      {isResult && <ScrollProgressBar />}
    </>
  );
};

type MenuProps = {
  useSimpleMenu?: boolean;
};
