import * as i from 'types';

export const getInfraLocation = (type: i.ChargingStation['infraType']) => {
  if (type === 'business') {
    return 'Op bedrijventerrein';
  }

  return 'Bij werknemer thuis';
};

export const getTimeUnit = (period: i.Period, isMobile?: boolean) => {
  if (isMobile) {
    return period === 'year' ? 'p/j' : 'p/m';
  }

  return period === 'year' ? 'per jaar' : 'per maand';
};

export const getTotalSavedEmissions = (costs: i.AllCostsType) => {
  if (costs.allCarsEmissions) {
    return (
      costs.allCarsEmissions?.totalEmissions.petrol.year -
      costs.allCarsEmissions?.totalEmissions.ev.year
    );
  }

  return 0;
};

export const getTotalSavedEmissionsPercentage = (costs: i.AllCostsType) => {
  if (costs.allCarsEmissions?.totalEmissions.ev.year === 0) return 100;

  if (costs.allCarsEmissions) {
    return Math.round(
      (costs.allCarsEmissions.totalEmissions.ev.year /
        costs.allCarsEmissions.totalEmissions.petrol.year) *
        100,
    );
  }

  return 0;
};
