import * as i from 'types';
import * as React from 'react';

import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';
import { Input } from 'common/form';
import { Paragraph } from 'common/typography';

import { RadioFormOption, InputContainer, Container } from './styled';

export const RadioWithFollowUpInputForm: React.FC<RadioWithFollowUpInputFormProps> = ({ questionId, options }) => {
  const { answers, updateAnswer, questions } = useQuestionnaireContext();
  const [selectedRadioValue, setSelectedRadioValue] = React.useState('');
  const [validationMessage, setValidationMessage] = React.useState<string | undefined>(undefined);
  const answer = answers?.[transformQuestionId(questionId)] as string;

  if (!questions) return null;

  // Find the radio options and after selecting filter out the selected radio option so it can be replaced with input field
  const radioOptions = options.filter((option) => {
    return option.type === 'radio' &&
    ((option.hasFollowUpQuestion && option.key !== selectedRadioValue) || !option.hasFollowUpQuestion);
  });
  const followUpInput = options.find((option) => option.type === 'followUpQuestion');

  const onSelect = (value: string) => {
    setSelectedRadioValue(value);

    updateAnswer({
      ...answers,
      [questionId]: followUpInput?.showCondition === value ? undefined : value,
    });
  };

  const onChange = (value: string) => {
    const validateAmount = (answer: string, amount: number) => {
      const isValid =  parseInt(answer) <= amount;
      // @ts-ignore
      setValidationMessage(isValid || answer === '' || isNaN(answer) ? undefined : followUpInput?.maxAmountValidation);
      return isValid ? answer : undefined;
    };

    updateAnswer({
      ...answers,
      [questionId]: followUpInput?.maxAmount ? validateAmount(value, followUpInput.maxAmount) : value,
    });
  };

  return (
    <Container maxWidth={!!followUpInput}>
      <Grid showGrid={radioOptions.length > 2}>
        {followUpInput?.showCondition === selectedRadioValue &&  (
          <InputContainer>
            {followUpInput.value}
            <Input
              name="input"
              onChange={(e) => onChange(e.target.value)}
              defaultValue={!!Number(answer) ? answer : ''}
            />
            {validationMessage && <Paragraph variant="red" noMargin>{validationMessage}</Paragraph>}
          </InputContainer>
        )}
        {radioOptions.map((option) => (
          option.key && (
            <RadioFormOption
              key={option.key}
              onClick={() => onSelect(option.key!)}
              isActive={answers?.[transformQuestionId(questionId)] === option.key}
              fullWidth={options.length > 2}
              id={`button_${option.key}`}
            >
              {option.value}
            </RadioFormOption>
          )
        ))}
      </Grid>
    </Container>
  );
};

type RadioWithFollowUpInputFormProps = {
  questionId: i.QuestionIds;
  options: i.Option[];
};
