export const validation = {
  required: {
    required: 'Dit veld is verplicht',
  },
  phone: {
    pattern: {
      value: /^\+?[0-9-() ]*$/,
      message: 'Vul een geldig telefoonnummer in',
    },
    maxLength: {
      value: 14,
      message: 'Telefoonnummer is te lang',
    },
    minLength: {
      value: 10,
      message: 'Telefoonnummer is te kort',
    },
  },
  email: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Vul een geldig email adres in',
    },
  },
  fleetRange: {
    minValue: {
      value: 1000,
      message: 'Getal moet minimaal 1.000 zijn.',
    },
    maxValue: {
      value: 100000,
      message: 'Getal moet kleiner dan 100.000 zijn.',
    },
  },
};
