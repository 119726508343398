import styled from 'styled-components';

export const StepsSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const StepsSliderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const StepsSliderIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:first-of-type {
    align-items: flex-start;
  }

  &:last-of-type {
    align-items: flex-end;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StepsSliderFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;

  p {
    font-size: 18px;
    height: 18px;
    line-height: 28px;
    margin: 0;
    font-weight: 500;
  }

  span {
    color: ${({ theme }) => theme.colors.orange['600']};
  }
`;
