import styled from 'styled-components';

import { media } from 'styles/utils';

export const Container = styled.div<ContainerProps>`
  width: 100%;

  ${media.tablet<ContainerProps>`
    width: ${({ width }) => width ? `${width}px` : 'unset'};
  `}
`;

type ContainerProps = {
  width?: number;
};
