import * as i from 'types';
import * as React from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { isEmpty } from 'lodash';

import { usePrevious } from 'hooks';
import { useQueryUser, useUpdateUser } from 'queries/users';
import {
  checkValid,
  contentSelector,
  isBrowser,
  transformQuestionId,
} from 'services';

export const QuestionnaireContext =
  React.createContext<QuestionnaireContextProps>(
    {} as QuestionnaireContextProps,
  );

export const useQuestionnaireContext = () => {
  const context = React.useContext(QuestionnaireContext);
  if (!context)
    throw new Error(
      'QuestionnaireContext cannot be rendered outside the Questionnaire component',
    );
  return context;
};

export const QuestionnaireProvider = ({
  data,
  children,
}: QuestionnaireProviderProps) => {
  const location = useLocation();
  const locationParts = location.pathname.split('/');
  const questionnaireUrl = locationParts[1];
  const isTcoTool = ['questions', 'result', ''].includes(questionnaireUrl);
  const initialPath = '/';

  const questionnaire = contentSelector(
    data.allContentfulQuestions,
  ) as i.Question[];
  const fleetOptions = contentSelector(data.allContentfulCars) as i.Car[];

  const questions = questionnaire;
  const localUserId = isBrowser && localStorage.getItem('userId');
  const resultUrl = `/result/${localUserId}`;

  const [userId, setUserId] = React.useState(localUserId || '');
  const [answers, setAnswers] = React.useState<i.TcoAnswers>({});
  const [isValid, setIsValid] = React.useState(false);
  const [path, setPath] = React.useState(initialPath);

  const { mutate: updateUser } = useUpdateUser();
  const { data: user } = useQueryUser(userId);

  const isQuestionnaireRoute =
    isTcoTool ||
    ['result', 'resultaat', 'use-cases'].includes(questionnaireUrl);
  const prevQuestionnaireRoute = usePrevious(isQuestionnaireRoute);

  // When answers.userType is 'consumer', filter out the questions that are typeOfFlow 'business', and vice versa.
  let flowTypeQuestions = questions;
  if (answers?.userType === 'consumer') {
    flowTypeQuestions = flowTypeQuestions.filter(
      (question) => question.typeOfFlow !== 'business',
    );
  } else if (answers?.userType === 'business') {
    flowTypeQuestions = flowTypeQuestions.filter(
      (question) => question.typeOfFlow !== 'consumer',
    );
  }

  const visibleQuestions = flowTypeQuestions.filter((question) => {
    // If the prop is falsy or have no conditions, the question should be visible
    if (!question.isVisible) return true;

    // @ts-ignore
    const answer = answers?.[
      transformQuestionId(question.isVisible.questionId)
    ] as string;
    // Walk through all visibility conditions if they are met
    return question.isVisible.answerValue.includes(answer);
  });

  const firstQuestionId = visibleQuestions[0].questionId;
  const [current, setCurrent] = React.useState<i.QuestionIds>(firstQuestionId);
  const progress = visibleQuestions.findIndex(
    (question) => question.questionId === current,
  );

  React.useEffect(() => {
    if (localUserId) {
      setUserId(localUserId);
    }
  }, [localUserId]);

  // If user is loaded set the previous answers
  React.useEffect(() => {
    if (user && isEmpty(answers)) setAnswers(user.answers);
  }, [answers, user]);

  //Remove already given answers for fleet and range if fleet type changes
  React.useEffect(() => {
    if (answers.fleetType && answers?.fleetType !== 'both') {
      const filter = answers?.fleetType === 'car' ? 'van' : 'car';

      // Remove cars and vans from answers.fleet
      answers?.fleet &&
        (Object.keys(answers.fleet) as i.KeyOfFleetInput[]).forEach(
          (vehicle) => {
            if (vehicle.startsWith(filter)) {
              delete answers?.fleet?.[vehicle];
            }
          },
        );

      // Remove cars and vans from answers.range
      answers?.range &&
        (Object.keys(answers.range) as i.KeyOfFleetInput[]).forEach(
          (vehicle) => {
            if (vehicle.startsWith(filter)) {
              delete answers?.range?.[vehicle];
            }
          },
        );

      updateAnswer(answers);
    }
  }, [answers?.fleetType]);

  // Remove any other answers when answers.userType changes. This to prevent taking 'consumer' answers into the 'business' flow, or vice versa.
  React.useEffect(() => {
    if (answers.userType) {
      setAnswers({
        userType: answers.userType,
      });
    }
  }, [answers?.userType]);

  // If user goes to questionnaire from contentful url
  // and has already answered some questions
  // navigate to the correct initial url
  React.useEffect(() => {
    if (
      location.pathname === '/questions/type' &&
      isQuestionnaireRoute !== prevQuestionnaireRoute &&
      isQuestionnaireRoute &&
      path !== '/questions/type'
    )
      navigate(path);
  }, [isQuestionnaireRoute]);

  // Update current question and paths when navigating to next question
  // and check wether it is valid already
  React.useEffect(() => {
    if (
      isQuestionnaireRoute === prevQuestionnaireRoute &&
      isQuestionnaireRoute
    ) {
      const questionId =
        locationParts[1] === 'questions'
          ? (locationParts[locationParts.length - 1] as i.QuestionIds)
          : firstQuestionId;

      const progress = visibleQuestions.findIndex(
        (question) => question.questionId === questionId,
      );
      setIsValid(Boolean(checkValid(visibleQuestions, progress, answers)));
      setCurrent(questionId);
      setPath(
        visibleQuestions[progress]
          ? `${questionnaireUrl}/${visibleQuestions[progress].questionId}`
          : resultUrl,
      );
    }
  }, [location.pathname, visibleQuestions]);

  const updateAnswer = (newAnswers: i.Answers) => {
    const updatedAnswers = { ...answers, ...newAnswers };

    setAnswers(updatedAnswers);
    setIsValid(Boolean(checkValid(visibleQuestions, progress, updatedAnswers)));

    if (userId) {
      updateUser({
        id: userId,
        answers: JSON.stringify(updatedAnswers),
      });
    }
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        current,
        progress,
        questions: visibleQuestions,
        answers,
        updateAnswer,
        isValid,
        fleetOptions,
        path,
        questionnaireUrl,
        resultUrl,
        visibleQuestions,
        initialPath,
        isTcoTool,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

type QuestionnaireContextProps = {
  answers?: i.Answers;
  isValid: boolean;
  current: i.QuestionIds;
  progress: number;
  questions?: i.Question[];
  updateAnswer: (answers: i.Answers) => void;
  fleetOptions?: i.Car[];
  path: string;
  questionnaireUrl: string;
  resultUrl: string;
  visibleQuestions: i.Question[];
  initialPath: string;
  isTcoTool: boolean;
};

type QuestionnaireProviderProps = {
  children: React.ReactNode;
  data: {
    allContentfulQuestions: GatsbyTypes.ContentfulQuestionsConnection;
    allContentfulCars: GatsbyTypes.ContentfulCarsConnection;
  };
};
