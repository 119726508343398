import * as i from 'types';

export const calcSavings = (
  costs: i.AllCostsType,
  carComparison: i.KeyOfFleetInput,
) => {
  const costsSavings =
    costs.totalIndiv.petrolPrice[carComparison] -
    costs.totalIndiv.evPrice[carComparison];
  const costsSavingsPercentage = Math.round(
    (costsSavings / costs.totalIndiv.petrolPrice[carComparison]) * 100,
  );
  const emissionsSavingsPercentage = Math.round(
    Number(
      costs.allCarsEmissions?.emissionsDifference[carComparison]?.percentage,
    ),
  );

  return {
    costsSavings,
    costsSavingsPercentage,
    emissionsSavingsPercentage,
  };
};
