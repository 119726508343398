import * as i from 'types';

export const calculateAllCarsEmissions = (
  cars: GatsbyTypes.ContentfulCars[],
  answers?: i.Answers,
): AllCarsEmissionsCalculations => {
  if (!answers) return null;

  let fleet: (GatsbyTypes.ContentfulCars & { chosenAmount: number })[] = [];

  if (answers?.fleet) {
    Object.entries(answers?.fleet as i.FleetInput).forEach(([key, amount]) => {
      const matchingCars = cars
        .filter((car) => car.comparison === key)
        .map((car) => ({ ...car, chosenAmount: amount }));

      fleet = [...fleet, ...matchingCars];
    });
  }

  const emissions: Emissions = {};

  fleet.forEach((car) => {
    const carComparison = car.comparison as i.KeyOfFleetInput;
    const emissionsPerKm = car.emissionsPerKm || 0;
    if (!carComparison || typeof answers.range?.[carComparison] !== 'number')
      return;

    // Will look together with Sander on how to best implement types here
    // @ts-ignore
    emissions[carComparison] = {
      ...emissions[carComparison],
      [car.type as string]: {
        // Emissions are defined in grams per KM
        km: emissionsPerKm,

        // Calculated by multiplying the emissions per km by the amount of km's driven in a year
        // Then divided by 1000 to store the emissions in KG's per year
        year: (emissionsPerKm * Number(answers?.range[carComparison])) / 1000,
        month:
          (emissionsPerKm * (Number(answers?.range[carComparison]) / 12)) /
          1000,
      },
      brand: car.brand,
      model: car.model,
      chosenAmount: car.chosenAmount,
      kmPerYear: answers?.range[carComparison],
    };
  });

  const emissionsDifference: EmissionsDifference = {};

  Object.entries(emissions).forEach(([car, value]) => {
    if (!value.petrol || !value.ev) return;
    const carKey = car as i.KeyOfFleetInput;

    emissionsDifference[carKey] = {
      year: Math.round(value.petrol.year - value.ev.year),
      month: Math.round(value.petrol.month - value.ev.month),
      // If electric cars have 0 emissions then the percentage diff will always be 100%
      percentage:
        value.ev.year === 0
          ? 100
          : Math.round((value.ev.year / value.petrol.year) * 100),
    };
  });

  let totalEmissions: TotalEmissions = {
    petrol: { year: 0, month: 0 },
    ev: { year: 0, month: 0 },
  };

  totalEmissions = {
    petrol: {
      year: Object.values(emissions).reduce(
        (acc, item) => acc + item.petrol.year * item.chosenAmount,
        0,
      ),
      month: Object.values(emissions).reduce(
        (acc, item) => acc + item.petrol.month * item.chosenAmount,
        0,
      ),
    },
    ev: {
      year: Object.values(emissions).reduce(
        (acc, item) => acc + item.ev.year * item.chosenAmount,
        0,
      ),
      month: Object.values(emissions).reduce(
        (acc, item) => acc + item.ev.month * item.chosenAmount,
        0,
      ),
    },
  };

  return {
    emissions,
    emissionsDifference,
    totalEmissions,
  };
};

type EmissionsData = {
  year: number;
  month: number;
  km: number;
};

type Emissions = i.PartialRecord<
  i.KeyOfFleetInput,
  {
    ev: EmissionsData;
    petrol: EmissionsData;
    kmPerYear?: number;
    brand: GatsbyTypes.ContentfulCars['brand'];
    model: GatsbyTypes.ContentfulCars['model'];
    chosenAmount: number;
  }
>;

type EmissionsDifference = i.PartialRecord<
  i.KeyOfFleetInput,
  {
    year: number;
    month: number;
    percentage: number;
  }
>;

export type AllCarsEmissionsCalculations = {
  emissions: Emissions;
  emissionsDifference: EmissionsDifference;
  totalEmissions: TotalEmissions;
} | null;

export type TotalEmissions = {
  petrol: {
    year: number;
    month: number;
  };
  ev: {
    year: number;
    month: number;
  };
};
