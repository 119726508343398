import styled, { css, keyframes } from 'styled-components';
import * as i from 'types';

import { media } from 'styles/utils';

export const buttonLoaderAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
`;

export const StyledButtonLoader = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
    animation: ${buttonLoaderAnimation} 1.4s infinite ease-in-out both;

    &:nth-child(1) {
      animation-delay: -0.32s;
    }

    &:nth-child(2) {
      animation-delay: -0.16s;
    }
  }
`;

export const ButtonIcon = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.button<i.ButtonProps>`
  color: ${({ theme }) => theme.colors.white};
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: fit-content;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.gray['1000']};
  border-radius: 4px;
  border: 0;
  transition: 0.2s ease;
  cursor: pointer;

  :hover,
  :active {
    background-color: ${({ theme }) => theme.colors.gray['1100']};
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 16px;
    height: 16px;
  }

  ${media.tablet`
    transition: background-color .2s, border-color .2s;

    + a,
    + button {
      margin: 0 0 0 8px;
    }
  `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      color: ${({ theme }) => theme.colors.gray['1000']};
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.gray['1000']};

      svg {
        fill: ${({ theme }) => theme.colors.gray['1000']};
      }

      :hover,
      :active {
        background-color: transparent;
        border: 1px solid ${({ theme }) => theme.colors.orange['600']};
      }
    `}

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      color: ${({ theme }) => theme.colors.orange['600']};
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.sand['200']};

      :hover {
        background-color: ${({ theme }) => theme.colors.orange['600']['800']};

        svg {
          fill: ${({ theme }) => theme.colors.white};
        }
      }

      :active {
        background-color: ${({ theme }) => theme.colors.orange['600']};
        outline: none;
      }

      svg {
        fill: ${({ theme }) => theme.colors.orange['600']};
      }
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      color: ${({ theme }) => theme.colors.gray['1200']};
      text-decoration: underline;
      text-underline-offset: 4px;
      background-color: transparent;

      :focus,
      :hover {
        text-decoration-color: ${({ theme }) => theme.colors.orange['600']};
        background-color: transparent;
      }

      svg {
        fill: ${({ theme }) => theme.colors.orange['600']};
      }
    `}

  ${({ size }) =>
    size === 'auto' &&
    css`
      width: auto;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      height: 46px;
    `}

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      cursor: not-allowed;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};

  ${({ $iconPosition }) =>
    $iconPosition === 'right' &&
    css`
      flex-direction: row-reverse;

      ${ButtonIcon} {
        margin: 0 0 0 8px;
      }
    `}

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      width: 48px;

      ${ButtonIcon} {
        margin: 0;
      }
    `}
`;
