import styled from 'styled-components';

import NNLogoIcon from 'vectors/logo/logo-nn.svg';

import { Button } from 'common/interaction';
import { media } from 'styles/utils';

export const MenuContainer = styled.header`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: ${({ theme }) => theme.sizes.headerHeight.mobile};
  padding: 0px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.sand['200']};
  z-index: 11;

  ${media.tablet`
    min-height: ${({ theme }) => theme.sizes.headerHeight.desktop};
  `}
`;

export const MenuContent = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1120px;
`;

export const MenuButton = styled(Button)`
  display: none;

  ${media.tablet`
    display: block
  `}
`;

export const NNLogo = styled(NNLogoIcon)`
  width: 144px;
  height: 44px;

  ${media.tablet`
    width: 187px;
    height: 55px;
  `}
`;
