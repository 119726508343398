import styled from 'styled-components';

import { media } from 'styles/utils';

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 24px;

  ${media.tablet<GridProps>`
    ${({ showGrid }) => showGrid && media.tablet<GridProps>`
      grid-template-columns: repeat(2, minmax(0, 1fr));
    `}
  `}
`;

type GridProps = {
  showGrid?: boolean;
};
