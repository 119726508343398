import * as React from 'react';
import reactStringReplace from 'react-string-replace';

// This regular expression filters [**] which are used in Contentful for adding a highlight color
// around that piece of text
export const regexDoubleStars = /\*\*(.*?)\*\*/g;

export const stringReplaceToSpan = (value?: string) =>
  reactStringReplace(value, regexDoubleStars, (text) => (
    <span key={text}>{text}</span>
  ));
