import * as i from 'types';
import * as React from 'react';

import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { formatPrice } from 'services';

import CheckmarkIcon from 'vectors/icons/checkmark.svg';

import { Label } from 'common/typography';
import { FleetSelectorPrice } from '../FleetSelector/styled';
import {
  CarSelectorCheckBox,
  CarSelectorDetails,
  CarSelectorDetailsContainer,
  CarSelectorImage,
  CarSelectorOption,
} from './styled';

export const CarSelector: React.FC<CarSelectorProps> = ({ questionId }) => {
  const { answers, updateAnswer, fleetOptions } = useQuestionnaireContext();
  const filter = questionId === 'fleetCar' ? 'car' : 'van';
  const amountOfSelectedOptions = answers?.fleet
    ? Object.keys(answers.fleet).length
    : 0;
  const userFlowFleetOptions = fleetOptions?.filter(
    (fleetOption) => fleetOption.typeOfFlow === answers?.userType,
  );

  const handleClick = (isActive: boolean, car: i.Car) => {
    let state = { ...answers };

    if (isActive) {
      state = {
        ...state,
        fleet: {
          ...(answers?.fleet as i.MultipleAnswer),
          [car.comparison]: 1,
        },
      };

      // Update range for the car || van automatically if user already
      // selected a range
      const hasRange =
        answers?.range &&
        Object.entries(answers.range).find(([key]) => key.startsWith(filter));

      if (hasRange) {
        state.range = {
          ...answers?.range,
          [car.comparison]: hasRange[1],
        };
      }
    } else {
      delete state?.fleet?.[car.comparison];
      delete state?.range?.[car.comparison];
    }

    updateAnswer(state);
  };

  if (!userFlowFleetOptions) return null;

  return (
    <Grid showGrid>
      {userFlowFleetOptions
        .filter(
          (fleet) =>
            fleet.vehicle === (questionId === 'fleetCar' ? 'car' : 'van'),
        )
        .map((car) => {
          const isActive = answers?.fleet?.[car.comparison] !== undefined;
          const isDisabled = amountOfSelectedOptions >= 3 && !isActive;

          return (
            <CarSelectorOption
              key={car.id}
              onClick={() => handleClick(!isActive, car)}
              isActive={isActive}
              disabled={isDisabled}
              isFlex
            >
              <CarSelectorDetailsContainer>
                <CarSelectorCheckBox isActive={isActive} disabled={isDisabled}>
                  {isActive && <CheckmarkIcon />}
                </CarSelectorCheckBox>
                <CarSelectorDetails>
                  <Label>{car.description}</Label>
                  <FleetSelectorPrice newSize={2} noMargin>
                    Vanaf {formatPrice(car.price)}
                  </FleetSelectorPrice>
                </CarSelectorDetails>
              </CarSelectorDetailsContainer>
              <CarSelectorImage src={car.image.file.url} alt={car.model} />
            </CarSelectorOption>
          );
        })}
    </Grid>
  );
};

type CarSelectorProps = {
  questionId: i.QuestionIds;
};
