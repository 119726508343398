import styled from 'styled-components';
import { media } from 'styles/utils';

export const FooterContainer = styled.footer`
  margin-top: auto;
  padding: 32px 0px;
  background-color: ${({ theme }) => theme.colors.sand['100']};

  ${media.tablet`
    padding: 40px 0px;
  `}
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 0px 16px;

  ${media.tablet`
    border-top: 1px solid ${({ theme }) => theme.colors.sand['200']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.sand['200']};
  `}
`;

export const FooterList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto;
  gap: 10px;

  ${media.tablet`
    padding: 16px 0px;
  `}
`;

export const FooterListItem = styled.li`
  &:not(:last-child) {
    padding-right: 8px;
    border-right: 1px solid ${({ theme }) => theme.colors.sand['200']}; // List item divider
  }
`;

export const FooterLink = styled.a`
  color: ${({ theme }) => theme.colors.gray['1000']};
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.gray['800']};
  }
`;
