import * as i from 'types';

export const calculatePaybackPeriod = (
  costs: i.AllCostsType,
  yearlyCostsSaving: number,
) => {
  const totalInvestment =
    costs.total.ev.oneOffMinusSubsidiesAndTaxes -
    costs.total.petrol.oneOffMinusSubsidiesAndTaxes;

  const paybackPeriod = totalInvestment / yearlyCostsSaving;

  return Math.abs(parseFloat(paybackPeriod.toFixed(1)));
};
