import styled from 'styled-components';

import { media } from 'styles/utils';

export const SubTitle = styled.span<SubTitleProps>`
  display: inline-block;
  margin: ${({ $margin }) => $margin || '0 0 12px 0'};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
  display: ${({ isCentered }) => (isCentered ? 'block' : 'initial')};
  font-size: 16px;

  ${media.tablet<SubTitleProps>`
    margin: ${({ $margin }) => $margin || '0 0 24px 0'};
  `}

  ${media.desktop<SubTitleProps>`
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '16px')};
  `}
`;

type SubTitleProps = {
  $margin?: string;
  fontSize?: number;
  isCentered?: boolean;
};
