import * as i from 'types';
import * as React from 'react';

import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { transformQuestionId } from 'services';

import { RadioFormOption, InputInput } from './styled';

export const InputCombination: React.FC<InputCombinationProps> = ({
  questionId, options,
}) => {
  const { answers, updateAnswer } = useQuestionnaireContext();
  const answerOptions = options.map((option) => option.answer);
  const answer = answers?.[transformQuestionId(questionId)] as string;
  const [textValue, setTextValue] = React.useState<string>();

  React.useEffect(() => {
    if (!answerOptions.includes(answer)) setTextValue(answer);
  }, []);

  const onSelect = (answer?: string) => {
    updateAnswer({
      ...answers,
      [questionId]: answer,
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    updateAnswer({
      ...answers,
      [questionId]: e.target.value,
    });
  };

  const onFocus = () => {
    updateAnswer({
      ...answers,
      [questionId]: textValue,
    });
  };

  return (
    <Grid>
      {options.map((option) => {
        return (
          option.answer ? (
            <RadioFormOption
              key={option.key}
              onClick={() => onSelect(option?.answer)}
              isActive={answer === option.answer}
              fullWidth={options.length > 2}
              id={`button_${option.key}`}
            >
              {option.value}
            </RadioFormOption>
          ) : (
            <RadioFormOption
              key={option.key}
              isActive={answer ? !answerOptions.includes(answer) : false}
              fullWidth={options.length > 2}
              id={`button_${option.key}`}
            >
              {option.value}
              <InputInput
                name="input"
                onChange={onChange}
                defaultValue={textValue}
                onFocus={onFocus}
              />
            </RadioFormOption>
          )
        );
      })}
    </Grid>
  );
};

type InputCombinationProps = {
  questionId: i.QuestionIds;
  options: {
    key: string;
    answer?: string;
    value: string;
  }[];
};
