import styled from 'styled-components';

import { media } from 'styles/utils';
import { BoxCss } from 'common/layout';
import { Paragraph } from 'common/typography';

export const NumberInputTitle = styled(Paragraph)`
  margin: 0px 0px 24px;

  ${media.tablet`
    margin: 0;
    padding-right: 12px;
  `}
`;

export const NumberInputFormOption = styled.div<NumberInputFormOptionProps>`
  ${BoxCss};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 136px;
  padding: 24px;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
    width: 465px;
    min-height: 104px;
  `}
`;

type NumberInputFormOptionProps = {
  isActive?: boolean;
  isFlex: boolean;
};
