import * as React from 'react';

import { ModalTrigger } from 'common/interaction';
import { Container } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';

import {
  CarSelector,
  DepreciationSlider,
  FleetRange,
  FleetSelector,
  InputCombination,
  MultiSelectForm,
  NumberInputForm,
  RadioCombination,
  RadioForm,
  RadioWithFollowUpInputForm,
  Slider,
  StepsSlider,
} from './components';
import {
  InfoParagraph,
  QuestionRouteAnswers,
  QuestionRouteDescription,
  QuestionRouteHeading,
} from './styled';

const Components = {
  radio: RadioForm,
  radioCombination: RadioCombination,
  radioCombinationType: RadioCombination,
  numberInput: NumberInputForm,
  carSelector: CarSelector,
  fleetInput: FleetSelector,
  fleetRange: FleetRange,
  depreciation: DepreciationSlider,
  inputCombination: InputCombination,
  slider: Slider,
  multiSelect: MultiSelectForm,
  radioWithFollowUpInput: RadioWithFollowUpInputForm,
  stepsSlider: StepsSlider,
};

export const QuestionRoute: React.FC = () => {
  const { current, questions } = useQuestionnaireContext();

  if (!questions) return null;
  const question = questions.find(
    (question) => question.questionId === current,
  );

  if (!question) return null;
  const Component = Components[question.type];

  return (
    <Container>
      <QuestionRouteDescription>
        <QuestionRouteHeading
          as="h1"
          size={2}
          width={680}
          margin="0"
          marginDesktop="0"
        >
          {question.question}
          {question.infoText && (
            <ModalTrigger
              text={question.infoText.infoText}
              title={question.infoTitle}
            />
          )}
        </QuestionRouteHeading>
        {question.relevantInfo && (
          <InfoParagraph noMargin>{question.relevantInfo}</InfoParagraph>
        )}
      </QuestionRouteDescription>
      <QuestionRouteAnswers>
        <Component
          questionId={question.questionId}
          // @ts-ignore
          options={question.options}
          // @ts-ignore
          sliderValues={question.sliderValues}
          secondaryQuestion={question.secondaryQuestion}
        />
      </QuestionRouteAnswers>
    </Container>
  );
};
