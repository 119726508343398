import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { BoxCss } from 'common/layout';

export const RadioFormOption = styled.div<RadioFormOptionProps>`
  ${BoxCss};

  ${media.tablet<RadioFormOptionProps>`
    width: 264px;

    ${({ fullWidth }) => fullWidth && css`
      width: 100%;
    `}
  `}
`;

type RadioFormOptionProps = {
  isActive: boolean;
  fullWidth?: boolean;
};

export const InputContainer = styled.div`
  ${BoxCss}
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
`;

export const Container = styled.div<ContainerProps>`
  max-width: ${({ maxWidth }) =>  maxWidth && '456px'};
`;

type ContainerProps = {
  maxWidth: boolean;
};
