import * as React from 'react';

import MinusSvg from 'vectors/icons/minus.svg';
import PlusSvg from 'vectors/icons/plus.svg';

import { NumberField, NumberInputButton, NumberInputContainer } from './styled';

export const NumberInput: React.FC<NumberInputProps> = ({
  initialValue,
  options,
  maxAmount,
  onChange,
  resetTrigger,
}) => {
  // Need to be number or string because should show the placeholder when
  // the number is 0. If you have null | undefined you will get a console error.
  const [number, setNumber] = React.useState<number | string>('');

  React.useEffect(() => {
    if (initialValue) setNumber(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setNumber(0);
  }, [resetTrigger]);

  const changeNumber = (value: number) => {
    if (value > 0) {
      if (maxAmount && value > maxAmount) {
        setNumber(maxAmount);
        onChange(maxAmount, options);
        return;
      }

      setNumber(value);
      onChange(value, options);
      return;
    }

    setNumber(0);
    onChange(0, options);
  };

  const selectNumber = () => (typeof number === 'number' ? number : 0);

  return (
    <NumberInputContainer>
      <NumberInputButton
        onClick={() => changeNumber(selectNumber() - 1)}
        icon={<MinusSvg />}
        variant="tertiary"
        aria-label="Min"
      />
      <NumberField
        type="number"
        placeholder="0"
        value={number}
        onChange={(e) => changeNumber(parseInt(e.target.value))}
      />
      <NumberInputButton
        onClick={() => changeNumber(selectNumber() + 1)}
        icon={<PlusSvg />}
        variant="tertiary"
        aria-label="Plus"
      />
    </NumberInputContainer>
  );
};

type NumberInputProps = {
  initialValue?: number;
  options: any;
  maxAmount?: number;
  onChange: (value: number, options: any) => void;
  resetTrigger?: boolean;
};
