import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import * as i from 'types';

import { fetcher } from 'services';

import {
  CREATE_USER,
  GET_USER_QUERY,
  UPDATE_USER,
  UPDATE_USER_OUTCOME,
} from './graphql';
import { selectUserAnswers } from './selectors';

export const useQueryUser = (
  id?: string,
): UseQueryResult<i.User | undefined> => {
  return useQuery(
    ['user', id],
    async () => await fetcher<string>(GET_USER_QUERY, { id: `${id}` }),
    {
      enabled: Boolean(id),
      select: (res) => ({
        ...res.getUser,
        answers: selectUserAnswers(JSON.parse(res.getUser?.answers)),
        busFinder: JSON.parse(res.getUser.busFinder),
      }),
    },
  );
};

export const useCreateUser = () => {
  return useMutation(async () => await fetcher(CREATE_USER));
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (user: i.UpdateUser) => await fetcher(UPDATE_USER, { ...user }),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['user', variables.id]);
      },
    },
  );
};

export const useUpdateUserOutcome = () => {
  return useMutation(
    async (user: i.UpdateUserOutcome) =>
      await fetcher(UPDATE_USER_OUTCOME, { ...user }),
  );
};
