import * as i from 'types';
import * as React from 'react';
import { navigate } from 'gatsby';

import { Button } from 'common/interaction';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { useCreateUser } from 'queries/users';
import { isBrowser } from 'services';
import ArrowLeft from 'vectors/icons/arrow-left.svg';
import ArrowRight from 'vectors/icons/arrow-right.svg';
import { QuestionnaireFooterBackButtonContainer } from './styled';

export const QuestionnaireFooter: React.FC = () => {
  const {
    current,
    isValid,
    questions,
    progress,
    questionnaireUrl,
    visibleQuestions,
    resultUrl,
    initialPath,
  } = useQuestionnaireContext();
  const localUserId = isBrowser && localStorage.getItem('userId');
  const { mutate: createUser } = useCreateUser();

  React.useEffect(() => {
    if (!localUserId) {
      createUser(undefined, {
        onSuccess: ({ createUser: user }: { createUser: i.User }) => {
          localStorage.setItem('userId', user.id);
        },
      });
    }
  }, [localUserId]);

  if (!questions) return null;

  const nextPath = visibleQuestions[progress + 1]
    ? `/${questionnaireUrl}/${visibleQuestions[progress + 1].questionId}`
    : resultUrl;

  const prevPath = questions[progress - 1]
    ? `/${questionnaireUrl}/${questions[progress - 1].questionId}`
    : initialPath;

  return (
    <>
      {prevPath && (
        <QuestionnaireFooterBackButtonContainer>
          <Button
            icon={<ArrowLeft />}
            variant="secondary"
            onClick={() => navigate(prevPath)}
            id={`button_back_${current}`}
            aria-label="Terug"
          />{' '}
          Terug
        </QuestionnaireFooterBackButtonContainer>
      )}
      {
        <Button
          icon={<ArrowRight />}
          $iconPosition="right"
          onClick={() => navigate(nextPath)}
          id={`button_next_${current}`}
          disabled={!isValid}
          type="submit"
        >
          Volgende
        </Button>
      }
    </>
  );
};
