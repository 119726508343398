import styled, { css } from 'styled-components';

import { BoxCss } from 'common/layout';
import { media } from 'styles/utils';

export const RadioFormOption = styled.label<RadioFormOptionProps>`
  ${BoxCss};
  gap: 12px;

  ${media.tablet<RadioFormOptionProps>`
    max-width: 456px;

    ${({ fullWidth }) =>
      fullWidth &&
      css`
        width: 100%;
      `}
  `}

  /* Custom radio button.: https://moderncss.dev/pure-css-custom-styled-radio-buttons/ */
  input[type="radio"] {
    appearance: none;
    display: grid;
    place-content: center;
    min-width: 24px;
    min-height: 24px;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.sand['200']};
    border-radius: 50%;
  }

  input[type='radio']:focus {
    border: 1px solid ${({ theme }) => theme.colors.orange['600']};
  }

  input[type='radio']::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.colors.orange['600']};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      input[type='radio']::after {
        transform: scale(1);
      }
    `}
`;

export const RadioFormThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 12px;

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 24px;
  `}
`;

type RadioFormOptionProps = {
  isActive: boolean;
  fullWidth?: boolean;
};
