import * as React from 'react';

import { Paragraph } from 'common/typography';
import {
  FooterContainer,
  FooterContent,
  FooterLink,
  FooterList,
  FooterListItem,
} from './styled';

const links = [
  {
    title: 'Over Nationale-Nederlanden',
    url: 'https://www.nn.nl/Over-NationaleNederlanden.htm',
  },
  {
    title: 'Maatschappelijk verantwoord ondernemen',
    url: 'https://www.nn.nl/Over-NationaleNederlanden/Maatschappelijk-verantwoord-ondernemen.htm',
  },
  {
    title: 'Cookieverklaring',
    url: 'https://www.nn.nl/Cookieverklaring.htm',
  },
  {
    title: 'Privacy',
    url: 'https://www.nn.nl/Mijn-privacy.htm',
  },
  {
    title: 'Disclaimer',
    url: 'https://www.nn.nl/Disclaimer.htm',
  },
];

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterList>
          {links.map((link) => (
            <FooterListItem key={link.title}>
              <Paragraph newSize={2} noMargin>
                <FooterLink href={link.url}>{link.title}</FooterLink>
              </Paragraph>
            </FooterListItem>
          ))}
        </FooterList>
      </FooterContent>
    </FooterContainer>
  );
};
