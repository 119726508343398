import * as React from 'react';
import * as i from 'types';

import { FormField } from '../FormField';
import { InputIcon, InputWrapper, StyledInput } from './styled';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoFocus,
      disabled,
      name,
      label,
      icon,
      $iconPosition = 'left',
      error,
      readOnly,
      direction,
      description,
      ...props
    },
    ref,
  ) => {
    const IconComponent = icon as React.ElementType;

    return (
      <FormField {...{ name, label, error, direction, description }}>
        <InputWrapper $iconPosition={$iconPosition}>
          <StyledInput
            {...{
              ...{ autoFocus, disabled, name, readOnly },
              ...(ref && { ref }),
            }}
            id={name}
            error={!!error}
            hasIcon={!!icon}
            {...props}
          />
          {icon && <InputIcon readOnly={readOnly}>{IconComponent}</InputIcon>}
        </InputWrapper>
      </FormField>
    );
  },
);

export type InputProps = i.DefaultInputProps &
  i.FormFieldProps & {
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
  };
