import { BoxCss } from 'common/layout';
import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const CarSelectorOption = styled.button<CarSelectorOptionProps>`
  ${BoxCss};
  width: 100%;
  min-height: 104px;
  padding: 24px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  ${media.desktop`
    min-width: 456px;
    min-height: 104px;
  `}
`;

export const CarSelectorDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CarSelectorDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CarSelectorCheckBox = styled.div<CarSelectorOptionProps>`
  display: grid;
  place-content: center;
  width: 32px;
  height: 32px;
  padding: 4px;
  border: 1px solid
    ${({ isActive, theme }) =>
      isActive ? theme.colors.orange['600'] : theme.colors.sand['200']};
  border-radius: 4px;

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.sand['200']};
      border: none;
    `}

  svg {
    width: 24px;
    height: 24px;
  }
`;

type CarSelectorOptionProps = {
  isActive?: boolean;
  isFlex?: boolean;
  disabled?: boolean;
};

export const CarSelectorImage = styled.img`
  width: 92px;

  ${media.desktop`
    width: 120px;
  `}
`;
