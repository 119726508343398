import * as React from 'react';

import { Footer, Menu } from './components';
import { PageContainer } from './styled';

export const Page: React.FC<PageProps> = ({ children, useSimpleMenu }) => {
  return (
    <PageContainer>
      <Menu {...{ useSimpleMenu: useSimpleMenu }} />
      <main>{children}</main>
      <Footer />
    </PageContainer>
  );
};

type PageProps = {
  useSimpleMenu?: boolean;
};
