import 'styled-components';

const theme = {
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    blue: {
      '100': '#EAF1F8',
      '700': '#4881BE',
    },
    gray: {
      'border': '#CFCECF',
      'disabled': '#F9F9F9',
      'background': '#F7F7F7',
      '800': '#6D6D6D',
      '1000': '#404040',
      '1100': '#2B2B2B',
      '1200': '#1A1A1A',
    },
    orange: Object.assign('#EA650D', {
      '300': '#FAC39F',
      '600': '#EA650D',
      '800': '#B54E0A',
    }),
    red: '#FF0000',
    sand: {
      '100': '#F3EFED',
      '200': '#E6DFDA',
    },
    green: Object.assign('#5D9C55', {
      '100': '#E9F2E7',
      '600': '#5D9C55',
    }),
  },
  ui: {
    shadows: Object.assign(
      '0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 6px 6px -6px rgba(0, 0, 0, 0.16);',
      {
        primary:
          '0px 8px 24px -6px rgba(0, 153, 255, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.4)',
      },
    ),
  },
  fonts: {
    nnNittiGrotesk: '"NN Nitti Grotesk", sans-serif',
  },
  sizes: {
    headerHeight: {
      mobile: '64px',
      desktop: '86px',
    },
  },
};

export default theme;
