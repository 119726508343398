import styled from 'styled-components';

import { media } from 'styles/utils';

export const SliderAnswerContainer = styled.div`
  margin-top: 16px;
`;

export const SliderAnswer = styled.div<AnswerProps>`
  text-align: center;
  width: 100%;

  ${media.desktop<AnswerProps>`
    margin-left: ${({ marginLeft, answerWidth }) => answerWidth && `calc(${marginLeft}% - ${answerWidth / 2}px)`};
    width: 'fit-content';
  `}
`;

type AnswerProps = {
  marginLeft: number;
  answerWidth?: number;
};

export const SliderTitle = styled.p`
  font-size: 32px;
  font-weight: bold;
  margin: 0;
`;

export const SliderSubtitle = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray['1000']};
  margin: 0;
`;
