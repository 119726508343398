import * as i from 'types';
import styled from 'styled-components';

import { getColor, media } from 'styles/utils';

const headingSizes = {
  1: {
    mobile: {
      size: '28px',
      lineHeight: '36px',
    },
    desktop: {
      size: '36px',
      lineHeight: '48px',
    },
  },
  2: {
    mobile: {
      size: '24px',
      lineHeight: '32px',
    },
    desktop: {
      size: '28px',
      lineHeight: '36px',
    },
  },
  3: {
    mobile: {
      size: '20px',
      lineHeight: '28px',
    },
    desktop: {
      size: '24px',
      lineHeight: '32px',
    },
  },
  4: {
    mobile: {
      size: '16px',
      lineHeight: '24px',
    },
    desktop: {
      size: '20px',
      lineHeight: '28px',
    },
  },
  5: {
    mobile: {
      size: '14px',
      lineHeight: '20px',
    },
    desktop: {
      size: '18px',
      lineHeight: '24px',
    },
  },
  6: {
    mobile: {
      size: '12px',
      lineHeight: '18px',
    },
    desktop: {
      size: '14px',
      lineHeight: '20px',
    },
  },
};

export const Heading = styled.h1<HeadingProps>`
  margin: ${({ margin }) => margin || '0 0 24px'};
  line-height: ${({ size }) => headingSizes[size || 1].mobile.lineHeight};
  font-weight: ${({ weight }) => weight || 600};
  font-size: ${({ size }) => headingSizes[size || 1].mobile.size};
  width: 100%;
  color: ${({ theme, variant }) =>
    getColor(theme, variant || ['gray', '1200'])};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};

  ${media.tablet<HeadingProps>`
    width: ${({ width }) => (width ? `${width}px` : 'unset')};
    font-size: ${({ size }) => headingSizes[size || 1].desktop.size};
    line-height: ${({ size }) => headingSizes[size || 1].desktop.lineHeight};
  `}

  ${media.desktop<HeadingProps>`
    margin: ${({ marginDesktop }) => marginDesktop || '0 0 24px'};
  `}
`;

type HeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  margin?: string;
  width?: number;
  weight?: number;
  variant?: i.TextColors;
  isCentered?: boolean;
  marginDesktop?: string;
};
