import styled from 'styled-components';

import { media } from 'styles/utils';

export const ScrollProgressBarContainer = styled.div<ScrollProgressBarContainerProps>`
  height: 4px;
  width: 100%;
  position: fixed;
  top: calc(${({ theme }) => theme.sizes.headerHeight.mobile} - 1px);
  left: 0;
  right: 0;
  z-index: 12;
  background: ${({ theme, $scrolled }) => `linear-gradient(
    to right,
    ${theme.colors.orange['600']} ${$scrolled}%,
    transparent 0
  )`};

  ${media.tablet`
     top: calc(
    ${({ theme }) => theme.sizes.headerHeight.desktop} - 1px
  );
  `}
`;

type ScrollProgressBarContainerProps = {
  $scrolled: number;
};
