import * as React from 'react';

import GlobalStyle from 'styles';
import { Meta } from 'common/general';

export const Template: React.FC<TemplateProps> = ({
  children,
  metaTitle,
  metaDescription,
  metaImage,
  metaUrl,
  dontIndex,
  dontFollow,
}) => {
  return (
    <>
      <Meta
        title={metaTitle}
        description={metaDescription}
        image={metaImage}
        url={metaUrl}
        dontIndex={dontIndex}
        dontFollow={dontFollow}
      />
      <GlobalStyle />
      {children}
    </>
  );
};

type TemplateProps = {
  metaTitle?: string;
  metaDescription?: string;
  metaImage?: string;
  metaUrl?: string;
  dontIndex?: boolean;
  dontFollow?: boolean;
};
