import styled from 'styled-components';

import { media } from 'styles/utils';

export const Columns = styled.div<ColumnsProps>`
  ${media.tablet<ColumnsProps>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: ${({ isReversed }) => isReversed ? 'row-reverse' : 'row'};
  `}
`;

type ColumnsProps = {
  isReversed?: boolean;
};

export const Column = styled.div<ColumnProps>`
  flex: 1;
  text-align: ${({ isCentered }) => isCentered ? 'center' : 'left'};
  margin-bottom: 32px;

  ${media.tablet`
    margin-bottom: 0;
  `}
`;

type ColumnProps = {
  isCentered?: boolean;
};
