import currency from 'currency.js';

export const formatPrice = (
  value: number, withSymbol = true, withPrecision = false, withHyphen = true,
) => {
  const formattedCurrency = currency(
    value,
    {
      separator: '.',
      decimal: ',',
      symbol: withSymbol ? '€' : '',
      precision: withPrecision ? 0 : 2,
    },
  ).format();
  const formattedCurrencyWithHyphen = formattedCurrency.replace(',00', ',-');

  return withHyphen ? formattedCurrencyWithHyphen : formattedCurrency;
};

export const formatNegativePrice = (value: number, withPrecision = false, withHyphen = true) => {
  const formattedCurrency = currency(
    value,
    {
      symbol: '€',
      separator: '.',
      decimal: ',',
      precision: withPrecision ? 0 : 2,
      negativePattern: '!#',
    },
  ).format();
  const formattedCurrencyWithHyphen = formattedCurrency.replace(',00', ',-');

  return withHyphen ? formattedCurrencyWithHyphen : formattedCurrency;
};
