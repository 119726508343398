
import 'css/fonts.css';
import { graphql, StaticQuery } from 'gatsby';
import { ThemeProvider } from 'styled-components';

// eslint-disable-next-line no-use-before-define
import { LocationProvider } from '@reach/router';
import * as React from 'react';
import { QueryClientProvider } from 'react-query';

import client from 'client';
import { QuestionnaireProvider } from 'modules/questionnaire';
import theme from 'styles/theme';

// This file needs to be in the /src folder otherwise it wil
// result in a Loading (StaticQuery).
export const wrapRootElement = ({ element }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <LocationProvider>
            <QueryClientProvider client={client}>
              <ThemeProvider theme={theme}>
                <QuestionnaireProvider data={{ ...data }}>
                  {element}
                </QuestionnaireProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </LocationProvider>
        );
      }}
    />
  );
};

export default wrapRootElement;

const query = graphql`
  query Question {
    allContentfulQuestions(sort: { fields: order }) {
      edges {
        node {
          id
          questionId
          typeOfFlow
          question
          relevantInfo
          type
          sliderValues {
            min
            max
            default
            step
            startIcon {
              id
              value
            }
            endIcon {
              id
              value
            }
          }
          options {
            maxAmount
            value
            answer
            type
            key
          }
          order
          infoTitle
          infoText {
            infoText
          }
          secondaryQuestion
          isVisible {
            questionId
            answerValue
          }
        }
      }
    }
    allContentfulCars(
      filter: { type: { eq: "ev" }, shouldBeInTcoTool: { eq: true } }
      sort: { fields: order }
    ) {
      edges {
        node {
          id
          typeOfFlow
          brand
          model
          description
          size
          operationalLeasePriceMonthlyBusiness
          insuranceCostsYearlyBusiness
          privateLeasePriceMonthlyConsumers
          financialLeasePriceMonthlyConsumers
          insuranceCostsYearlyConsumers
          seppSubsidyConsumers
          price
          shouldBeInTcoTool
          vehicle
          comparison
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
