import * as React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import {
  TooltipArrow,
  TooltipButton,
  TooltipContent,
  TooltipIcon,
  TooltipParagraph,
} from './styled';

export const Tooltip = ({ title }: TooltipProps) => {
  return (
    <RadixTooltip.Provider delayDuration={400}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          <TooltipButton>
            <TooltipIcon />
          </TooltipButton>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <TooltipContent align="start" alignOffset={-24} sideOffset={4}>
            <TooltipParagraph newSize={2} noMargin>
              {title}
            </TooltipParagraph>
            <TooltipArrow />
          </TooltipContent>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

type TooltipProps = {
  title: string;
};
