import * as i from 'types';
import * as React from 'react';

import { NumberInput } from 'common/form';
import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { formatPrice, transformQuestionId } from 'services';

import { Label } from 'common/typography';
import {
  FleetSelectorCarImage,
  FleetSelectorDetails,
  FleetSelectorInputContainer,
  FleetSelectorOption,
  FleetSelectorPrice,
} from './styled';

export const FleetSelector: React.FC<FleetSelectorProps> = ({ questionId }) => {
  const { answers, updateAnswer, fleetOptions } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as i.FleetInput;
  const filter = questionId === 'fleetCar' ? 'car' : 'van';
  const userFlowFleetOptions = fleetOptions?.filter(
    (fleetOption) => fleetOption.typeOfFlow === answers?.userType,
  );

  const onChange = (value: number, car: i.Car) => {
    let state = { ...answers };

    if (value === 0) {
      delete state?.fleet?.[car.comparison];
      delete state?.range?.[car.comparison];
    } else {
      state = {
        ...answers,
        fleet: {
          ...(answers?.fleet as i.MultipleAnswer),
          [car.comparison]: value,
        },
      };

      // Update range for the car || van automatically if user already
      // selected a range
      const hasRange =
        answers?.range &&
        Object.entries(answers.range).find(([key]) => key.startsWith(filter));

      if (hasRange) {
        state.range = {
          ...answers?.range,
          [car.comparison]: hasRange[1],
        };
      }
    }

    updateAnswer(state);
  };

  if (!userFlowFleetOptions) return null;

  return (
    <Grid showGrid>
      {userFlowFleetOptions
        .filter((fleet) => fleet.vehicle === filter)
        .map((car) => {
          const sizeSelector = answer?.[car.comparison];
          const isActive = sizeSelector ? sizeSelector > 0 : false;

          return (
            <FleetSelectorOption key={car.model} isActive={isActive} isFlex>
              <FleetSelectorInputContainer>
                <FleetSelectorDetails>
                  <Label>{car.description}</Label>
                  <FleetSelectorPrice newSize={2} noMargin>
                    Vanaf {formatPrice(car.price)}
                  </FleetSelectorPrice>
                </FleetSelectorDetails>
                <NumberInput
                  onChange={onChange}
                  options={car}
                  initialValue={answer?.[car.comparison]}
                />
              </FleetSelectorInputContainer>
              <FleetSelectorCarImage src={car.image.file.url} alt={car.model} />
            </FleetSelectorOption>
          );
        })}
    </Grid>
  );
};

type FleetSelectorProps = {
  questionId: i.QuestionIds;
};
