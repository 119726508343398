import * as i from 'types';

import { carSelector } from 'services';
import { calculateSolarPanels } from './calculateSolarPanels';

export const calculateOneOffInvestment = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  if (!answers)
    return {
      evPrice: 0,
      petrolPrice: 0,
    };

  const fleetCosts = calculateFleetInvestments(cars, answers);
  const infrastructureCosts = calculateInfrastructureInvestments(
    variables,
    answers,
  );
  const solarPanelCosts = calculateSolarPanels(answers)?.oneOffCosts || 0;

  // Car costs will only be added with financial or operational lease
  // Infrastructure and solar panels will always be added
  return {
    evPrice: fleetCosts.evPrice + infrastructureCosts + solarPanelCosts,
    petrolPrice: fleetCosts?.petrolPrice,
  };
};

export const calculateFleetInvestments = (
  cars: GatsbyTypes.ContentfulCars[],
  answers?: i.Answers,
) => {
  if (!answers) {
    return {
      evPrice: 0,
      petrolPrice: 0,
    };
  }

  const { financing, fleet } = answers;

  let evPrice = 0;
  let petrolPrice = 0;

  if ((financing === 'self' || financing === 'unknown') && fleet) {
    evPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        if (!car?.price) return 0;
        return car.price * value;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);

    petrolPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        if (!car?.price) return 0;
        return car.price * value;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateFleetInvestmentsIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  const evPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  const petrolPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers) {
    return {
      evPrice,
      petrolPrice,
    };
  }

  const { financing, fleet } = answers;

  if ((financing === 'self' || financing === 'unknown') && fleet) {
    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const ev = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );
        const petrol = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        if (ev?.price && petrol?.price) {
          evPrice[key] = ev.price * value;
          petrolPrice[key] = petrol.price * value;
        }
      },
    );
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateInfrastructureInvestments = (
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  if (!answers) return 0;
  const { chargingStation, chargingAmount } = answers;
  const { infrastructureHigh, infrastructureLow } = variables;

  if (
    chargingStation === 'yes' &&
    chargingAmount &&
    infrastructureHigh &&
    infrastructureLow
  ) {
    return Object.entries(chargingAmount)
      .map(([key, value]) => {
        return calculateInfrastructurePrice(
          key as i.ChargingAmountKeys,
          value,
          infrastructureHigh,
          infrastructureLow,
        );
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  if (chargingStation === 'yes-home' && infrastructureLow) {
    return infrastructureLow;
  }

  return 0;
};

export const calculateInfrastructureInvestmentsIndiv = (
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  let infraIndiv = {
    employees: 0,
    business: 0,
  };

  if (!answers) return infraIndiv;

  const { chargingStation, chargingAmount } = answers;
  const { infrastructureHigh, infrastructureLow } = variables;

  if (
    chargingStation === 'yes' &&
    chargingAmount &&
    infrastructureHigh &&
    infrastructureLow
  ) {
    (Object.keys(chargingAmount) as i.ChargingAmountKeys[]).forEach(
      (amount) => {
        infraIndiv[amount] = calculateInfrastructurePrice(
          amount as i.ChargingAmountKeys,
          chargingAmount[amount],
          infrastructureHigh,
          infrastructureLow,
        );
      },
    );
  }

  if (chargingStation === 'yes-home' && infrastructureLow) {
    return (infraIndiv = {
      employees: infrastructureLow,
      business: 0,
    });
  }

  return infraIndiv;
};

export const calculateInfrastructurePrice = (
  key: i.ChargingAmountKeys,
  poleAmount: number,
  infrastructureHigh: number,
  infrastructureLow: number,
) => {
  const maxInfrastructureWithLowCosts = 5;

  if (
    key === 'employees' ||
    (key === 'business' && poleAmount <= maxInfrastructureWithLowCosts)
  ) {
    return infrastructureLow * poleAmount;
  }

  const high =
    (poleAmount - maxInfrastructureWithLowCosts) * infrastructureHigh;
  const low = maxInfrastructureWithLowCosts * infrastructureLow;

  return high + low;
};
