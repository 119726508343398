import * as React from 'react';
import { useLocation } from '@reach/router';

import {
  ProgressBar,
  ProgressBarText,
  ProgressBarWrapper,
} from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';

import { Menu } from '../Page/components';
import { Content, Footer, QuestionnairePageContainer } from './styled';

export const QuestionnairePage: PageType = ({ children }) => {
  const location = useLocation();
  const { progress, questions, answers } = useQuestionnaireContext();
  const isQuestion =
    location.pathname.includes('/questions') ;
  const isResult = location.pathname.includes('/result');
  // Only show the progress bar on questions pages. Not on the welcome or results pages.
  const showProgressBar = questions && isQuestion && !isResult;

  return (
    <>
      <Menu useSimpleMenu />
      <main>
        <QuestionnairePageContainer>
          {showProgressBar && (
            <ProgressBarWrapper>
              <ProgressBarText>
                <b>Stap {progress + 1}</b>{' '}
                {answers?.userType && ` / ${questions.length}`}
              </ProgressBarText>
              <ProgressBar $value={((progress + 1) / questions.length) * 100} />
            </ProgressBarWrapper>
          )}
          {children}
        </QuestionnairePageContainer>
      </main>
    </>
  );
};

QuestionnairePage.Content = (props) => {
  return <Content {...props} />;
};

QuestionnairePage.Footer = (props) => {
  return <Footer {...props} />;
};

type PageType = React.FC & {
  Content: React.FC<ContentProps>;
  Footer: React.FC;
};

type ContentProps = {
  tabletCollapse?: boolean;
};
