import { css } from 'styled-components';

export const BoxCss = css<BoxCssType>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.ui.shadows};
  padding: 16px 24px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.orange['600']};
    `}

  ${({ isFlex }) =>
    isFlex &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
`;

type BoxCssType = {
  isActive?: boolean;
  isFlex?: boolean;
};
