import * as React from 'react';
import { Helmet } from 'react-helmet';

export const Meta: React.FC<MetaProps> = ({
  title,
  description,
  image,
  url,
  pathname,
  article = false,
  dontIndex,
  dontFollow,
}) => {
  const seo = {
    title:
      title ||
      'Nationale-Nederlanden | Wat kost elektrisch rijden? | Bereken nu',
    description:
      description ||
      'Nationale-Nederlanden | Wat kost elektrisch rijden? | Bereken nu',
    image: image,
    url: url || `https://elektrischrijden.nn.nl${pathname || '/'}`,
  };

  return (
    <Helmet
      title={seo.title}
      htmlAttributes={{
        lang: 'nl',
      }}
    >
      <meta
        name="robots"
        content={`${dontIndex ? 'noindex' : 'index'},${
          dontFollow ? 'nofollow' : 'follow'
        }`}
      />
      <meta
        name="facebook-domain-verification"
        content="s1s18lkdo87u0ee7kr7j6yp45cbie3"
      />
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {article ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

type MetaProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  pathname?: string;
  article?: boolean;
  dontIndex?: boolean;
  dontFollow?: boolean;
};
