import * as i from 'types';

import { carSelector } from 'services';

export const calculateEmissions = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: any,
  type: 'Ev' | 'Pet',
  answers?: i.Answers,
) => {
  if (!answers) return;
  const { fleet, depreciation, range } = answers;
  const emissions = {
    fixed: {
      carOne: 0,
      carTwo: 0,
      carThree: 0,
      carFour: 0,
      carFive: 0,
      carSix: 0,
      vanOne: 0,
      vanTwo: 0,
      vanThree: 0,
      vanFour: 0,
      vanFive: 0,
      vanSix: 0,
    },
    var: {
      carOne: 0,
      carTwo: 0,
      carThree: 0,
      carFour: 0,
      carFive: 0,
      carSix: 0,
      vanOne: 0,
      vanTwo: 0,
      vanThree: 0,
      vanFour: 0,
      vanFive: 0,
      vanSix: 0,
    },
  } as any;

  const mapCarToVariable = {
    carOne: 'Passenger',
    carTwo: 'Passenger',
    carThree: 'Passenger',
    carFour: 'Passenger',
    carFive: 'Passenger',
    carSix: 'Passenger',
    vanOne: 'Small',
    vanTwo: 'Small',
    vanThree: 'Medium',
    vanFour: 'Medium',
    vanFive: 'Large',
    vanSix: 'Large',
  };

  let totalEmissions = 0;

  if (!fleet || !depreciation || !range) return emissions;

  Object.entries(fleet).forEach(([key, value]) => {
    const car = carSelector(
      cars,
      key as keyof i.FleetInput,
      type === 'Ev' ? 'ev' : 'petrol',
      answers,
      Boolean(type === 'Ev'),
    );
    const vehicleType = key as keyof i.FleetInput;
    const vehicleRange = range[vehicleType];

    if (!car?.fixedEmissions || !vehicleRange) return;

    const varType = `emissionKm${type}${mapCarToVariable[vehicleType]}`;
    const vehicleFixedEmissions = value * car?.fixedEmissions;
    const vehicleVarEmissions = value * variables?.[varType];
    const typeEmission =
      vehicleFixedEmissions +
      vehicleVarEmissions * (vehicleRange / 1000) * parseInt(depreciation);

    return (totalEmissions += typeEmission);
  });

  return totalEmissions;
};
