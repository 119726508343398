import * as i from 'types';
import * as React from 'react';

import { Grid } from 'common/layout';
import { useQuestionnaireContext } from 'modules/questionnaire';

import { FleetRangeSelectorOption } from './components';

export const FleetRange: React.FC<FleetRangeProps> = ({ questionId }) => {
  const { answers, fleetOptions } = useQuestionnaireContext();
  const [showVans, setShowVans] = React.useState<string[]>([]);
    const userFlowFleetOptions = fleetOptions?.filter(
      (fleetOption) => fleetOption.typeOfFlow === answers?.userType,
    );

  React.useEffect(() => {
    containsVehicle('car');
    containsVehicle('van');
  }, [answers]);

  const containsVehicle = (type: 'car' | 'van') => {
    const firstCar =
      answers?.fleet &&
      Object.keys(answers.fleet).find((vehicle) => vehicle.startsWith(type));

    if (firstCar) setShowVans((items) => [...items, firstCar]);
  };

  if (!userFlowFleetOptions) return null;

  return (
    <Grid showGrid>
      {userFlowFleetOptions.map(
        (car) =>
          showVans?.includes(car.comparison) && (
            <FleetRangeSelectorOption
              key={car.model}
              {...{ car, questionId }}
            />
          ),
      )}
    </Grid>
  );
};

type FleetRangeProps = {
  questionId: i.QuestionIds;
};
