import * as i from 'types';

import { carSelector } from 'services';

export const calculateFixedCosts = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  if (!answers)
    return {
      evPrice: 0,
      petrolPrice: 0,
    };

  const leaseCosts = calculateLease(cars, period, answers);
  const insuranceCosts = calculateInsurance(cars, period, answers);
  const maintenanceCosts = calculateMaintenance(cars, period, answers);
  const taxesCosts = calculateTaxes(cars, period, answers);

  if (answers.financing === 'operational') {
    return {
      evPrice: leaseCosts.evPrice,
      petrolPrice: leaseCosts.petrolPrice + taxesCosts,
    };
  } else {
    return {
      evPrice:
        leaseCosts.evPrice + insuranceCosts.evPrice + maintenanceCosts.evPrice,
      petrolPrice:
        leaseCosts.petrolPrice +
        insuranceCosts.petrolPrice +
        maintenanceCosts.petrolPrice +
        taxesCosts,
    };
  }
};

export const calculateLease = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  if (!answers)
    return {
      evPrice: 0,
      petrolPrice: 0,
    };

  const { financing, fleet } = answers;
  let evPrice = 0;
  let petrolPrice = 0;

  if ((financing === 'financial' || financing === 'operational') && fleet) {
    const isBusinessFlow = answers?.userType === 'business';

    const financialLeasePriceMonthly = isBusinessFlow
      ? 'financialLeasePriceMonthlyBusiness'
      : 'financialLeasePriceMonthlyConsumers';

    const operationalLeasePriceMonthly = isBusinessFlow
      ? 'operationalLeasePriceMonthlyBusiness'
      : 'privateLeasePriceMonthlyConsumers';

    const leasePrice =
      financing === 'financial'
        ? financialLeasePriceMonthly
        : operationalLeasePriceMonthly;

    evPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );
        const price = car?.[leasePrice];

        if (!price) return 0;

        const multiplier = period === 'year' ? 12 : 1;
        return price * value * multiplier;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);

    petrolPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );
        const price = car?.[leasePrice];

        if (!price) return 0;

        const multiplier = period === 'year' ? 12 : 1;
        return price * value * multiplier;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateLeaseIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  const evPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };
  const petrolPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers)
    return {
      evPrice,
      petrolPrice,
    };

  const { financing, fleet } = answers;

  if ((financing === 'financial' || financing === 'operational') && fleet) {
    const isBusinessFlow = answers?.userType === 'business';

    const financialLeasePriceMonthly = isBusinessFlow
      ? 'financialLeasePriceMonthlyBusiness'
      : 'financialLeasePriceMonthlyConsumers';

    const operationalLeasePriceMonthly = isBusinessFlow
      ? 'operationalLeasePriceMonthlyBusiness'
      : 'privateLeasePriceMonthlyConsumers';

    const leasePrice =
      financing === 'financial'
        ? financialLeasePriceMonthly
        : operationalLeasePriceMonthly;

    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const carEv = carSelector(cars, key, 'ev', answers, true);
        const carPetrol = carSelector(cars, key, 'petrol', answers);
        const priceEv = carEv?.[leasePrice];
        const pricePetrol = carPetrol?.[leasePrice];

        if (!priceEv || !pricePetrol) return 0;

        const multiplier = period === 'year' ? 12 : 1;
        evPrice[key] = priceEv * value * multiplier;
        petrolPrice[key] = pricePetrol * value * multiplier;
      },
    );
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateInsurance = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  if (!answers)
    return {
      evPrice: 0,
      petrolPrice: 0,
    };
  const { financing, fleet } = answers;
  let evPrice = 0;
  let petrolPrice = 0;
  const isBusinessFlow = answers?.userType === 'business';

  if (financing !== 'operational' && fleet) {
    evPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        const insuranceCostsYearly = isBusinessFlow
          ? car?.insuranceCostsYearlyBusiness
          : car?.insuranceCostsYearlyConsumers;

        if (!insuranceCostsYearly) return 0;

        const divider = period === 'year' ? 1 : 12;
        return (insuranceCostsYearly * value) / divider;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);

    petrolPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        const insuranceCostsYearly = isBusinessFlow
          ? car?.insuranceCostsYearlyBusiness
          : car?.insuranceCostsYearlyConsumers;

        if (!insuranceCostsYearly) return 0;

        const divider = period === 'year' ? 1 : 12;
        return (insuranceCostsYearly * value) / divider;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateInsuranceIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  const evPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };
  const petrolPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers) return { evPrice, petrolPrice };

  const { financing, fleet } = answers;

  if (financing !== 'operational' && fleet) {
    const isBusinessFlow = answers?.userType === 'business';

    const insuranceCostsYearly = isBusinessFlow
      ? 'insuranceCostsYearlyBusiness'
      : 'insuranceCostsYearlyConsumers';

    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const carEv = carSelector(cars, key, 'ev', answers, true);
        const carPetrol = carSelector(cars, key, 'petrol', answers);
        const insuranceEv = carEv?.[insuranceCostsYearly];
        const insurancePetrol = carPetrol?.[insuranceCostsYearly];

        if (!insuranceEv || !insurancePetrol) return 0;

        const divider = period === 'year' ? 1 : 12;
        evPrice[key] = (insuranceEv * value) / divider;
        petrolPrice[key] = (insurancePetrol * value) / divider;
      },
    );
  }
  return { evPrice, petrolPrice };
};

export const calculateMaintenance = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  if (!answers)
    return {
      evPrice: 0,
      petrolPrice: 0,
    };
  const { financing, fleet } = answers;
  let evPrice = 0;
  let petrolPrice = 0;

  if (financing !== 'operational' && fleet) {
    evPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        if (!car?.maintenanceCostsYearly) return 0;

        const divider = period === 'year' ? 1 : 12;
        return (car?.maintenanceCostsYearly * value) / divider;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);

    petrolPrice = Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        if (!car?.maintenanceCostsYearly) return 0;

        const divider = period === 'year' ? 1 : 12;
        return (car?.maintenanceCostsYearly * value) / divider;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateMaintenanceIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  const evPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };
  const petrolPrice = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers)
    return {
      evPrice,
      petrolPrice,
    };

  const { financing, fleet } = answers;

  if (financing !== 'operational' && fleet) {
    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const carEv = carSelector(cars, key, 'ev', answers, true);
        const carPetrol = carSelector(cars, key, 'petrol', answers);

        if (
          !carEv?.maintenanceCostsYearly ||
          !carPetrol?.maintenanceCostsYearly
        )
          return 0;

        const divider = period === 'year' ? 1 : 12;
        evPrice[key] = (carEv?.maintenanceCostsYearly * value) / divider;
        petrolPrice[key] =
          (carPetrol?.maintenanceCostsYearly * value) / divider;
      },
    );
  }
  return {
    evPrice,
    petrolPrice,
  };
};

export const calculateTaxes = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  if (!answers) return 0;
  const { fleet, financing } = answers;

  if (fleet) {
    return Object.entries(fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        if (!car?.taxesCostsYearly) return 0;
        if (financing === 'operational') return 0;

        const divider = period === 'year' ? 1 : 12;
        return (car?.taxesCostsYearly * value) / divider;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return 0;
};

export const calculateTaxesIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  period: 'year' | 'month',
  answers?: i.Answers,
) => {
  const perCarPetrol = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers) return perCarPetrol;
  const { fleet, financing } = answers;

  if (fleet) {
    (Object.entries(fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const carPetrol = carSelector(
          cars,
          key as keyof i.FleetInput,
          'petrol',
          answers,
        );

        if (!carPetrol?.taxesCostsYearly) return 0;
        if (financing === 'operational') return 0;

        const divider = period === 'year' ? 1 : 12;
        perCarPetrol[key] = (carPetrol?.taxesCostsYearly * value) / divider;
      },
    );
  }

  return perCarPetrol;
};
