import * as i from 'types';

// Checks if given answers are from old version, if so maps the
// old cars to new cars.
export const selectUserAnswers = (answers: OldAnswers) => {
  const oldToNewCars = {
    passenger: 'carOne',
    small: 'vanOne',
    medium: 'vanThree',
    large: 'vanFive',
  };
  const rangeKeys = answers?.range ? Object.keys(answers.range) : [];
  const fleetKeys = answers?.fleet ? Object.keys(answers.fleet) : [];

  let oldAnswers = false;

  Object.keys(oldToNewCars).forEach((oldCar) => {
    if (rangeKeys.includes(oldCar) || fleetKeys.includes(oldCar)) {
      oldAnswers = true;
    }
  });

  if (oldAnswers) {
    if (answers.fleet) {
      Object.keys(answers.fleet).forEach((fleetKey) => {
        const oldCar = fleetKey as keyof OldFleetInput;
        const ownDescriptor = Object.getOwnPropertyDescriptor(answers.fleet, fleetKey);

        if (ownDescriptor) {
          Object.defineProperty(answers.fleet, oldToNewCars[oldCar], ownDescriptor);
          delete answers.fleet[oldCar];
        }
      });
    }

    if (answers.range) {
      Object.keys(answers.range).forEach((rangeKey) => {
        const oldCar = rangeKey as keyof OldFleetInput;
        const ownDescriptor = Object.getOwnPropertyDescriptor(answers.range, rangeKey);

        if (ownDescriptor) {
          Object.defineProperty(answers.range, oldToNewCars[oldCar], ownDescriptor);
          delete answers.range[oldCar];
        }
      });
    }
  }

  return answers;
};

type OldFleetInput = {
  passenger?: number;
  small?: number;
  medium?: number;
  large?: number;
};

type OldAnswers = Omit<i.Answers, 'fleet' | 'range'> & {
  fleet: OldFleetInput;
  range: OldFleetInput;
};
