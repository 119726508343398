export const calculateOutcome = (
  evPrice: number, petroPrice: number,
) => {
  const percentage = evPrice / petroPrice * 100 - 100;

  if (percentage > 10) {
    return 'non-favorable';
  } else if (percentage < -10) {
    return 'favorable';
  }

  return 'neutral';
};
