import { createGlobalStyle } from 'styled-components';

const globalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    min-height: 100%;
    height: 100%;
  }

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.nnNittiGrotesk};
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.gray['1200']};
  }

  main {
    // Create a centered column with a max-width of 1120px and 16px margin on each side.
    display: grid;
    grid-template-columns: 1fr min(1120px, calc(100% - 32px)) 1fr;

    // All children of main will be in the center column by default.
    // If a child component needs to be full viewport width, you can escape it with: grid-column: 1 / -1;
    & > * {
      grid-column: 2;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

  button {
    font-family: inherit;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export default globalStyle;
