import { GraphQLClient } from 'graphql-request';

import { getApiUrl } from 'services';

export const fetcher = async <T>(
  query: string,
  variables?: Record<string, T>,
) => {
  const endpoint = getApiUrl();

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': process.env.GATSBY_REACT_APP_API_KEY as string,
    },
  });

  return await graphQLClient.request(query, variables);
};
