import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  width: 100%;
  padding: ${({ padding }) => (padding ? '64px 24px' : '0 24px')};

  ${media.tablet<ContentContainerProps>`
    padding: ${({ paddingTablet }) => paddingTablet || '0'};
    width: ${({ width }) => (width ? `${width}px` : '1400px')};
    max-width: 100%;
    margin: 0 auto;
  `}

  ${media.desktop<ContentContainerProps>`
    padding: ${({ padding }) => padding || '0'};
  `}
`;

type ContentContainerProps = {
  width?: number;
  paddingTablet?: string;
  padding?: string;
};
