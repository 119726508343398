import * as React from 'react';
import { createPortal } from 'react-dom';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { Heading } from 'common/typography';
import {
  ModalBackground,
  ModalClose,
  ModalContent,
  ModalOverlay,
  ModalWrapper,
} from './styled';

export const ModalContainer: React.FC<ModalProps> = ({
  children,
  onClose,
  title,
}) => {
  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const closeModal = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose();
    };

    if (modalRef.current) {
      disableBodyScroll(modalRef.current);
    }

    window.addEventListener('keyup', closeModal);

    return () => {
      clearAllBodyScrollLocks();
      window.removeEventListener('keyup', closeModal);
    };
  }, [modalRef, onClose]);

  return createPortal(
    <ModalOverlay>
      <ModalBackground onClick={onClose} />
      <ModalWrapper>
        <ModalContent>
          {title && <Heading size={4}>{title}</Heading>}
          {children}
        </ModalContent>
      </ModalWrapper>
    </ModalOverlay>,
    document.getElementById('modal') as Element,
  );
};

export type ModalProps = {
  title?: string;
  onClose: () => void;
};

export const Modal = {
  Container: ModalContainer,
  Close: ModalClose,
};
