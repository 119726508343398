import * as i from 'types';

import { carSelector } from 'services';
import { calculateSolarPanelsAmount } from './calculateSolarPanels';

export const calculateSubsidy = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  if (!answers) return 0;

  if (answers.userType === 'consumer' && answers.fleet) {
    return Object.entries(answers.fleet)
      .map(([key]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        if (car?.seppSubsidyConsumers) {
          return car.seppSubsidyConsumers;
        }
        return 0;
      })
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  const { subsidyPercentagePerCar, maxSubsidyAmountPerCar } = variables;

  if (
    answers.financing !== 'operational' &&
    answers.fleet &&
    subsidyPercentagePerCar &&
    maxSubsidyAmountPerCar
  ) {
    const percentagePerCar = subsidyPercentagePerCar / 100;

    return Object.entries(answers.fleet)
      .map(([key, value]) => {
        const car = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        // You will not get subsidy on cars
        if (!car?.price || car.comparison?.startsWith('car')) return 0;

        const subAmount = car.price * percentagePerCar;
        const amount =
          subAmount > maxSubsidyAmountPerCar
            ? maxSubsidyAmountPerCar
            : subAmount;
        return amount * value;
      })
      .reduce((partial_sum, a) => partial_sum + a, 0);
  }

  return 0;
};

export const calculateSubsidyIndiv = (
  cars: GatsbyTypes.ContentfulCars[],
  variables: GatsbyTypes.ContentfulVariables,
  answers?: i.Answers,
) => {
  const subsidyPerCar = {
    carOne: 0,
    carTwo: 0,
    carThree: 0,
    carFour: 0,
    carFive: 0,
    carSix: 0,
    vanOne: 0,
    vanTwo: 0,
    vanThree: 0,
    vanFour: 0,
    vanFive: 0,
    vanSix: 0,
  };

  if (!answers) return subsidyPerCar;
  const { subsidyPercentagePerCar, maxSubsidyAmountPerCar } = variables;

  if (
    answers.financing !== 'operational' &&
    answers.fleet &&
    subsidyPercentagePerCar &&
    maxSubsidyAmountPerCar
  ) {
    const percentagePerCar = subsidyPercentagePerCar / 100;

    (Object.entries(answers.fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key, value]) => {
        const carEv = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );
        if (!carEv?.price || carEv.comparison?.startsWith('car')) return 0;

        const subAmount = carEv?.price * percentagePerCar;
        const amount =
          subAmount > maxSubsidyAmountPerCar
            ? maxSubsidyAmountPerCar
            : subAmount;

        subsidyPerCar[key] = amount * value;
      },
    );
  }

  if (answers.userType === 'consumer' && answers.fleet) {
    (Object.entries(answers.fleet) as [i.KeyOfFleetInput, number][]).forEach(
      ([key]) => {
        const carEv = carSelector(
          cars,
          key as keyof i.FleetInput,
          'ev',
          answers,
          true,
        );

        subsidyPerCar[key] = carEv?.seppSubsidyConsumers
          ? carEv?.seppSubsidyConsumers
          : 0;
      },
    );
  }

  return subsidyPerCar;
};

export const calculateSubsidyInfra = (answers?: i.Answers) => {
  return Object.values(calculateSubsidyInfraIndiv(answers)).reduce(
    (acc, val) => acc + val,
    0,
  );
};

export const calculateSubsidyInfraIndiv = (answers?: i.Answers) => {
  const subsidyPerInfra = {
    solarPanels: 0,
  };

  const amountOfSolarPanels = calculateSolarPanelsAmount(answers);

  // You will only receive ISDE subsidy when you have more than 40 solar panels
  // The subsidy for one solar panel is 40,625 euros per solar panel
  if (amountOfSolarPanels > 39) {
    subsidyPerInfra.solarPanels = amountOfSolarPanels * 40.625;
  }

  return subsidyPerInfra;
};
