import * as React from 'react';

import {
  RangeSliderContainer,
  RangeSliderInput,
  RangeSliderStepIndicator,
} from './styled';

export const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  defaultValue,
  answer,
  onChange,
  step = '1',
  steps,
}) => {
  const calculateBackgroundValue = () => {
    const range = parseInt(max) - parseInt(min);
    const multiplier = parseInt(answer) - parseInt(min);
    return (100 / range) * multiplier;
  };

  const calculateOffsetLeft = (index: number) => {
    if (steps) {
      const totalSteps = steps - 1;
      const scale = 100 / totalSteps;
      return index * scale;
    }
  };

  return (
    <RangeSliderContainer>
      {steps &&
        Array.from({ length: steps }, (_, index) => {
          // Determine if the step indicator should be marked as passed
          const passedStepIndicator =
            parseInt(answer) >= parseInt(min) + index * parseInt(step);

          // Calculate offset left for each step indicator
          const offsetLeft = calculateOffsetLeft(index);

          return (
            <RangeSliderStepIndicator
              key={index}
              $passedStepIndicator={passedStepIndicator}
              $isActive={
                parseInt(answer) === parseInt(min) + index * parseInt(step)
              }
              $offsetLeft={`${offsetLeft}%`}
            />
          );
        })}
      <RangeSliderInput
        type="range"
        min={min}
        max={max}
        step={step}
        value={answer || defaultValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        backgroundValue={calculateBackgroundValue()}
      />
    </RangeSliderContainer>
  );
};

type RangeSliderProps = {
  min: string;
  max: string;
  defaultValue?: string;
  answer: string;
  step?: string;
  steps?: number;
  onChange: (value: string) => void;
  isDeprecationSlider?: boolean;
};
