import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const QuestionnairePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Fallback for older browser
  min-height: 100svh;
  padding-top: calc(${({ theme }) => theme.sizes.headerHeight.mobile} + 24px);
  gap: 64px;

  ${media.tablet`
    padding-top: calc(${({ theme }) =>
      theme.sizes.headerHeight.desktop} + 80px);
    gap: 100px;
  `}
`;

export const Content = styled.div<ContentProps>`
  width: 100%;

  ${media.tablet<ContentProps>`
    align-items: center;
    display: flex;
  `}

  ${({ tabletCollapse }) =>
    tabletCollapse &&
    css`
      ${media.tablet`
      height: unset;
      display: block;
    `}

      ${media.desktop<ContentProps>`
        display: flex;
      `}
    `};
`;

type ContentProps = {
  tabletCollapse?: boolean;
};

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;

  ${media.tablet`
    margin-bottom: 40px;
  `}
`;
