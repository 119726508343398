import * as React from 'react';
import { ScrollProgressBarContainer } from './styled';

export const ScrollProgressBar = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    window.requestAnimationFrame(() => {
      const scrollTop = window.scrollY;
      const winHeight = window.innerHeight;
      const docHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      const totalDocScrollLength = docHeight - winHeight;
      const newScrollPosition = Math.floor(
        (scrollTop / totalDocScrollLength) * 100,
      );

      setScrollPosition(newScrollPosition);
    });
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return <ScrollProgressBarContainer $scrolled={scrollPosition} />;
};
