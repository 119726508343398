import styled from 'styled-components';
import { media } from 'styles/utils';

import { Heading, Paragraph } from 'common/typography';

export const QuestionRouteDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  ${media.tablet`
    margin-bottom: 48px;
  `}
`;

export const QuestionRouteHeading = styled(Heading)`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  text-wrap: balance;

  & > button {
    top: 0;
    margin: 0;
  }
`;

export const InfoParagraph = styled(Paragraph)`
  margin-bottom: 16px;
`;

export const QuestionRouteAnswers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
