import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100svh;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: hidden;
`;
