import * as i from 'types';
import * as React from 'react';

import { RangeSlider } from 'common/form';
import { Paragraph } from 'common/typography';
import { useQuestionnaireContext } from 'modules/questionnaire';
import { stringReplaceToSpan, transformQuestionId } from 'services';
import HighwayIcon from 'vectors/icons/highway.svg';
import InnerCityIcon from 'vectors/icons/inner-city.svg';

import {
  StepsSliderContainer,
  StepsSliderFooter,
  StepsSliderHeader,
  StepsSliderIcon,
} from './styled';

const icons = {
  innerCity: InnerCityIcon,
  highway: HighwayIcon,
};

export const StepsSlider = ({
  questionId,
  options,
  sliderValues,
}: StepsSliderProps) => {
  const { answers, updateAnswer, questions } = useQuestionnaireContext();
  const answer = answers?.[transformQuestionId(questionId)] as string;
  const {
    min,
    max,
    step,
    default: defaultValue,
    startIcon,
    endIcon,
  } = sliderValues;
  const StartIcon = startIcon && icons[startIcon.id];
  const EndIcon = endIcon && icons[endIcon.id];

  React.useEffect(() => {
    if (answers) {
      updateAnswer({
        ...answers,
        [questionId]: (answer || defaultValue).toString(),
      });
    }
  }, [sliderValues]);

  if (!questions) return null;

  const onChange = (value: string) => {
    updateAnswer({
      ...answers,
      [questionId]: value,
    });
  };

  return (
    <StepsSliderContainer>
      <StepsSliderHeader>
        {startIcon && (
          <StepsSliderIcon>
            {StartIcon && <StartIcon />}
            {stringReplaceToSpan(startIcon.value)}
          </StepsSliderIcon>
        )}
        {endIcon && (
          <StepsSliderIcon>
            {EndIcon && <EndIcon />}
            {stringReplaceToSpan(endIcon.value)}
          </StepsSliderIcon>
        )}
      </StepsSliderHeader>
      <RangeSlider
        {...{ min, max, defaultValue, step, answer, onChange }}
        steps={options.length}
      />
      <StepsSliderFooter>
        <Paragraph>
          {stringReplaceToSpan(
            options.find((option) => Number(option.key) === Number(answer))
              ?.value,
          )}
        </Paragraph>
      </StepsSliderFooter>
    </StepsSliderContainer>
  );
};

type StepsSliderProps = {
  questionId: i.QuestionIds;
  options: i.Option[];
  sliderValues: i.SliderValues;
};
