import styled from 'styled-components';

import { BoxCss } from 'common/layout';
import { Paragraph } from 'common/typography';
import { media } from 'styles/utils';

export const FleetSelectorInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  ${media.desktop`
    gap: 24px;
    flex-direction: row;
  `}
`;

export const FleetSelectorDetails = styled.div`
  ${media.desktop`
    width: 144px;
  `}
`;

export const FleetSelectorOption = styled.div<FleetSelectorOptionProps>`
  ${BoxCss};
  width: 100%;
  min-height: 168px;
  padding: 24px;
  gap: 24px;
  cursor: auto;

  ${media.desktop`
    max-width: 456px;
    min-height: 104px;
  `}
`;

type FleetSelectorOptionProps = {
  isActive?: boolean;
  isDisabled?: boolean;
  isFlex: boolean;
};

export const FleetSelectorPrice = styled(Paragraph)`
  padding: 8px 0px 12px;

  ${media.desktop`
    padding: 8px 0px 0px;
  `}
`;

export const FleetSelectorCarImage = styled.img`
  width: 124px;

  ${media.desktop`
    width: 104px;
  `}
`;
