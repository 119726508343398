import styled from 'styled-components';

import { Button } from 'common/interaction';

export const NumberInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NumberField = styled.input`
  height: 40px;
  width: 40px;
  border: 1px solid ${({ theme }) => theme.colors.sand['200']};
  font-size: 16px;
  text-align: center;
  z-index: 2;
  margin: 0 -4px;
  -moz-appearance: textfield;
  appearance: none;
`;

export const NumberInputButton = styled(Button)`
  border-radius: 4px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 12px;
`;
