import React from 'react';

export const useAccordion = () => {
  const [open, setOpen] = React.useState<string | null>(null);

  const itemIsOpen = (item: string) => open === item;
  const toggleItem = (item: string) => {
    if (itemIsOpen(item)) setOpen(null);
    else setOpen(item);
  };

  return {
    itemIsOpen,
    toggleItem,
  };
};
