import styled, { css } from 'styled-components';
import { media } from 'styles/utils';

export const RangeSliderContainer = styled.div`
  position: relative;
`;

export const RangeSliderStepIndicator = styled.div`
  display: none;

  ${media.tablet<RangeSliderStepIndicatorProps>`
    position: absolute;
    top: 10px;
    left: ${({ $offsetLeft }) => $offsetLeft || 0};
    height: 12px;
    width: 12px;
    display: ${({ $isActive }) => ($isActive ? 'none' : 'flex')};
    align-items: center;
    justify-content: center;
    background-color: ${({ $passedStepIndicator, theme }) =>
      $passedStepIndicator
        ? theme.colors.orange['600']
        : theme.colors.sand['200']};
    border-radius: 50%;
    pointer-events: none;
  `}
`;

type RangeSliderStepIndicatorProps = {
  $isActive?: boolean;
  $offsetLeft?: string;
  $passedStepIndicator?: boolean;
};

export const RangeSliderThumb = css`
  appearance: none;
  width: 28px;
  height: 28px;
  border-radius: 24px;
  background: url('https://images.ctfassets.net/sfx6cvkt3nm2/Bnt786YXvpO6Ocz9jqLHB/7ecfbf5f60f8f3c3a5c60a089f8a694f/arrow-sides.svg'); // TODO: use a local svg file
  background-color: ${({ theme }) => theme.colors.white};
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.colors.orange['600']};
  cursor: pointer;
`;

export const RangeSliderInput = styled.input<RangeInputProps>`
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 6px;
  margin: 0;
  background: ${({ theme, backgroundValue }) =>
    `linear-gradient(
      to right,
      ${theme.colors.orange['600']} ${backgroundValue}%,
      ${theme.colors.sand['200']} ${backgroundValue}%)
    `};

  ::-webkit-slider-thumb {
    ${RangeSliderThumb}
    z-index: 99;
  }

  ::-moz-range-thumb {
    ${RangeSliderThumb}
  }
`;

type RangeInputProps = {
  backgroundValue: number;
};
