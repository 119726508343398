import * as i from 'types';
import styled from 'styled-components';

import { getColor } from 'styles/utils';

export const Highlight = styled.div<HighlightProps>`
  color: ${({ theme, variant }) => getColor(theme, variant || 'black')};
  border-radius: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid ${({ theme }) => theme.colors.sand['200']};
`;

type HighlightProps = {
  variant?: i.ThemeColors | [i.ThemeColors, i.SubThemeColors[i.ThemeColors]];
  color?: i.ThemeColors | [i.ThemeColors, i.SubThemeColors[i.ThemeColors]];
};
